"use client";
import React, { useState } from "react";
import PropTypes from "prop-types";
import HowToIntro from "../mgm/how-to-intro";
import HowToInstruction from "../mgm/how-to-instruction";
import HowToLink from "../mgm/how-to-link";
import BackBanner from "../shared/back-banner";

import { useIsDesktopTablet, useIsMobile } from "@/hooks/use-is-mobile";
import { ToastComponent } from "../toast";
import { urlAsset } from "@/helper/utils";

export function MGMHowTo({
  title,
  img_url,
  how_to_join_title,
  how_to_join_description,
  link_description,
  cta_label,
}) {
  const isMobile = useIsMobile()
  const isTab = useIsDesktopTablet()
  const [isSuccess, setIsSuccess] = useState(false)
  const dssPos = isMobile || isTab ? "object-center" : "object-bottom"
  const resPos = isMobile ? "object-top" : "object-center"
  const dssObj = isMobile || isTab ? "cover " : "fill"

  const brandName = process.env.NEXT_PUBLIC_NAME;
  const extension = brandName === "magnum" ? "jpg" : "png";
  const mgmbackbanner =
    isMobile || isTab ? `m-bg.${extension}` : `bg.${extension}`;

  return (
    <>
      <ToastComponent
        onClick={() => {
          setIsSuccess(false)
        }}
        title={`Berhasil copy Link`}
        desc={`Ajak Teman ${brandName === "djisamsoe" ? "Kamu" : "Lo"} Sebanyak-banyaknya!`}
        isOpen={isSuccess}
      />
      <BackBanner
        bgImg={`${urlAsset()}/mgm/mgm-${mgmbackbanner}`}
        className={`h-[768px] z-[-1] bg-bg-5 border-transparent border-none`}
        imgClass={brandName === "djisamsoe" ? dssPos : resPos}
        object={brandName === "djisamsoe" ? dssObj : "cover"}
      ></BackBanner>
      <HowToLink
        isCustom={false}
        link_description={link_description}
        cta_label={cta_label}
        setIsSuccess={setIsSuccess}
      />
      <HowToIntro title={title} imgUrl={img_url} />
      <HowToInstruction
        how_to_join_description={how_to_join_description}
        how_to_join_title={how_to_join_title}
      />
    </>
  )
}

MGMHowTo.propTypes = {
  title: PropTypes.string,
  img_url: PropTypes.string,
  bg_img_url: PropTypes.string,
  how_to_join_title: PropTypes.string,
  how_to_join_description: PropTypes.string,
  link_title: PropTypes.string,
  link_description: PropTypes.string,
  cta_label: PropTypes.string,
  link: PropTypes.string,
  bg_link_img_url: PropTypes.string,
}
