import { cn } from "@/lib/utils"
import React, { useRef } from "react"
import { RemoveScroll } from "react-remove-scroll"
import SlideRadio from "./slide-radio"
import { motion } from "framer-motion"
import { useIsDesktop, useIsMobile } from "@/hooks/use-is-mobile"
import ButtonAction from "../shared/button-action/button-action"
import { Image } from "../shared/image/image"
import Link from "@/components/shared/link/link"
import { SwiperComp } from "../swiper/swiper"
import { SwiperSlide } from "swiper/react"

const CustomizeTabMenuBtn = ({ children, isActive, handleMenu }) => {
  return (
    <button
      onClick={() => handleMenu(children)}
      className={cn(
        "text-[16px] leading-[24px] font-medium py-[10px] text-center min-h-[46px] transition ease-out duration-300 w-full",
        isActive ? "text-text-4" : "text-text-5"
      )}
    >
      {children}
    </button>
  )
}

const CustomizeTabIndicator = ({ isActive }) => {
  const isMobile = useIsMobile()

  return (
    <div
      style={{
        background: isMobile
          ? isActive
            ? "var(--cta_2)"
            : "var(--text_5)"
          : isActive
            ? "var(--text_5)"
            : "var(--cta_2)",
      }}
      className={cn(
        " w-full absolute bottom-0 lg:bottom-[-15px] transition ease-out duration-300",
        isActive
          ? "h-[2px] bg-cta-2 lg:bg-text-5"
          : "h-[1px] bg-text-5 lg:bg-cta-2"
      )}
    ></div>
  )
}

const Template1 = ({ isActive, handleClick }) => {
  return (
    <button
      onClick={handleClick}
      style={{ background: isActive ? "var(--cta_1)" : "var(--cta_2)" }}
      className={cn(
        "w-[50px] h-[50px] rounded-[5px] overflow-hidden flex items-center transition ease-out duration-300",
        isActive ? "bg-cta-1" : "bg-cta-2"
      )}
    >
      <div
        style={{ background: isActive ? "var(--cta_2)" : "var(--text_3)" }}
        className="flex items-center justify-center w-full h-full max-w-[36px] max-h-[36px] mx-auto rounded-[5px] overflow-hidden p-[1px]"
      >
        <div
          style={{ background: isActive ? "var(--cta_1)" : "var(--cta_2)" }}
          className={cn(
            "rounded-[4px] w-[34px] h-[34px] overflow-hidden mx-auto"
          )}
        >
          <div
            style={{ background: isActive ? "var(--cta_2)" : "var(--text_3)" }}
            className={cn("h-[18px]", isActive ? "bg-cta-2" : "bg-text-3")}
          ></div>
          <div
            style={{ background: isActive ? "var(--cta_2)" : "var(--text_3)" }}
            className={cn(
              "w-full max-w-[21px] h-[2px] mt-[4px] ml-[4px]",
              isActive ? "bg-cta-2" : "bg-text-3"
            )}
          ></div>
          <div
            style={{ background: isActive ? "var(--cta_2)" : "var(--text_3)" }}
            className={cn(
              "w-full max-w-[21px] h-[2px] mt-[2px] ml-[4px]",
              isActive ? "bg-cta-2" : "bg-text-3"
            )}
          ></div>
        </div>
      </div>
    </button>
  )
}

const Template2 = ({ isActive, handleClick }) => {
  return (
    <button
      onClick={handleClick}
      style={{ background: isActive ? "var(--cta_1)" : "var(--cta_2)" }}
      className={cn(
        "w-[50px] h-[50px] rounded-[5px] flex items-center transition ease-out duration-300",
        isActive ? "bg-cta-1" : "bg-cta-2"
      )}
    >
      <div
        style={{ background: isActive ? "var(--cta_2)" : "var(--text_3)" }}
        className="flex items-center justify-center w-full h-full max-w-[36px] max-h-[36px] mx-auto rounded-[5px] overflow-hidden p-[1px]"
      >
        <div
          style={{ background: isActive ? "var(--cta_1)" : "var(--cta_2)" }}
          className={cn(
            "rounded-[4px] w-[34px] h-[34px] mx-auto py-[3px] px-[4px]"
          )}
        >
          <div
            style={{ background: isActive ? "var(--cta_2)" : "var(--text_3)" }}
            className={cn(
              "h-[21px] w-[18px]",
              isActive ? "bg-cta-2" : "bg-text-3"
            )}
          ></div>
          <div
            style={{ background: isActive ? "var(--cta_2)" : "var(--text_3)" }}
            className={cn(
              "w-full max-w-[21px] h-[2px] mt-[3px]",
              isActive ? "bg-cta-2" : "bg-text-3"
            )}
          ></div>
          <div
            style={{ background: isActive ? "var(--cta_2)" : "var(--text_3)" }}
            className={cn(
              "w-full max-w-[21px] h-[2px] mt-[2px]",
              isActive ? "bg-cta-2" : "bg-text-3"
            )}
          ></div>
        </div>
      </div>
    </button>
  )
}

const Template3 = ({ isActive, handleClick }) => {
  return (
    <button
      onClick={handleClick}
      style={{ background: isActive ? "var(--cta_1)" : "var(--cta_2)" }}
      className={cn(
        "w-[50px] h-[50px] rounded-[5px]  flex items-center transition ease-out duration-300",
        isActive ? "bg-cta-1" : "bg-cta-2"
      )}
    >
      <div
        style={{ background: isActive ? "var(--cta_2)" : "var(--text_3)" }}
        className="flex items-center justify-center w-full h-full max-w-[36px] max-h-[36px] mx-auto rounded-[5px] overflow-hidden p-[1px]"
      >
        <div
          style={{ background: isActive ? "var(--cta_1)" : "var(--cta_2)" }}
          className={cn(
            "rounded-[4px] w-[34px] h-[34px] overflow-hidden mx-auto p-[4px]"
          )}
        >
          <div
            style={{ background: isActive ? "var(--cta_2)" : "var(--text_3)" }}
            className={cn(
              "w-full max-w-[21px] h-[2px] mt-[0]",
              isActive ? "bg-cta-2" : "bg-text-3"
            )}
          ></div>
          <div
            style={{ background: isActive ? "var(--cta_2)" : "var(--text_3)" }}
            className={cn(
              "w-full max-w-[21px] h-[2px] mt-[2px]",
              isActive ? "bg-cta-2" : "bg-text-3"
            )}
          ></div>
          <div
            style={{ background: isActive ? "var(--cta_2)" : "var(--text_3)" }}
            className={cn(
              "h-[17px] w-[26px] mt-[4px]",
              isActive ? "bg-cta-2" : "bg-text-3"
            )}
          ></div>
        </div>
      </div>
    </button>
  )
}

const variants = {
  show: {
    opacity: 1,
    y: 0,
    display: "block",
  },
  hide: {
    opacity: 0,
    y: 100,
    display: "none",
  },
  transition: {
    ease: "easeInOut",
    duration: 0.3,
  },
}

const CustomizeTab = ({
  isShow,
  handleMenu,
  selectedMenu,
  menus,
  handleClose,
  templates,
  handleTemplate,
  selectedTemplate,
  fonts,
  selectedFont,
  handleFont,
  handleUnderline,
  isUnderline,
  selectedImage,
  handleImage,
  mobileImages,
  dekstopImages,
  handleSubmit,
  isActive,
  isPosterExist,
  isSubmitting,
  imageCats,
  handleCat,
  selectedCat,
}) => {
  const isDesktop = useIsDesktop()
  const swiperRef = useRef(null)
  const catSwiperRef = useRef(null)
  const _AMILDORMLB = process.env.NEXT_PUBLIC_NAME === "amild" || process.env.NEXT_PUBLIC_NAME === "marlboro"

  const imageToRender = isDesktop ? dekstopImages : mobileImages

  const handleRenderTemplate = (render) => {
    const props = {
      isActive: selectedTemplate === render,
      handleClick: () => handleTemplate(render),
    }
    if (render === templates[0]) {
      return <Template1 {...props} />
    } else if (render === templates[1]) return <Template2 {...props} />
    else return <Template3 {...props} />
  }

  const handleRenderFont = (render) => {
    return (
      <li key={render} className="max-h-[50px] max-w-[50px]">
        <button
          onClick={() => handleFont(render)}
          style={{
            fontFamily: `${render}`,
            background: selectedFont === render ? "var(--cta_1)" : "#6B6969BF",
          }}
          className={cn(
            "transition ease-out duration-300 min-w-[50px] min-h-[50px] max-h-[50px] max-w-[50px] rounded-[5px] text-[20px] leading-[22px] font-black p-[1px] relative",
            selectedFont === render ? "bg-cta-1" : "bg-[#6B6969BF]",
            `font-[${render}]`
          )}
        >
          <div
            style={{
              background:
                selectedFont === render
                  ? "var(--cta_1)"
                  : _AMILDORMLB
                    ? "black"
                    : "var(--cta_2)",
            }}
            className={cn(
              "absolute inset-[1px] justify-center items-center flex text-center rounded-[4px]",
              selectedFont === render
                ? "bg-cta-1"
                :_AMILDORMLB
                  ? "black"
                  : "bg-cta-2"
            )}
          >
            Aa
          </div>
        </button>
      </li>
    )
  }

  const handleImageCats = (render, index) => {
    return (
      <SwiperSlide key={index} className="!w-fit">
        <button
          onClick={() => {
            handleImage(0)
            handleCat(render)
          }}
          style={{
            background: selectedCat === render ? "var(--cta_1)" : "#6B6969BF",
          }}
          className={cn(
            "transition ease-out duration-300  min-h-[30px] rounded-[10px] text-[10] leading-[12px] tracking-[1%] font-black relative",
            selectedCat === render ? "bg-cta-1" : "bg-[#6B6969BF]"
          )}
        >
          <div
            style={{
              background:
                selectedCat === render
                  ? "var(--cta_1)"
                  : _AMILDORMLB
                    ? "black"
                    : "var(--cta_2)",
            }}
            className={cn(
              "relative justify-center min-h-[26px] px-[10px] items-center flex text-center rounded-[8px] mx-[2px]",
              selectedCat === render
                ? "bg-cta-1"
                : _AMILDORMLB
                  ? "black"
                  : "bg-cta-2"
            )}
          >
            {render}
          </div>
        </button>
      </SwiperSlide>
    )
  }

  const handleRenderImages = (render, isSwiper, index) => {
    const imageElem = (
      <li className="max-h-[50px] max-w-[50px]">
        <button
          onClick={() => handleImage(index)}
          style={{
            background:
              selectedImage === index ? "var(--cta_1)" : "var(--cta_2)",
          }}
          className={cn(
            "transition ease-out duration-300 w-[50px] h-[50px] rounded-[5px] text-[8px] font-black p-[1px] relative flex overflow-hidden",
            selectedImage === index ? "bg-cta-1" : "bg-cta-2"
          )}
        >
          <div
            className={cn(
              "rounded-[4px] overflow-hidden justify-center items-center flex text-center rounded-[4px] text-text-2 w-full h-full"
            )}
          >
            <Image alt="image-option" src={render} />
          </div>
        </button>
      </li>
    )
    if (isSwiper)
      return (
        <SwiperSlide className="!w-fit" key={render}>
          {imageElem}
        </SwiperSlide>
      )
    return imageElem
  }

  return (
    <motion.div
      key="animation-on-state"
      variants={variants}
      animate={isShow || isDesktop ? "show" : "hide"}
      style={{ background: !isDesktop ? "#000000BF" : "transparent" }}
      className={`absolute lg:relative lg:h-[100%] lg:overflow-hidden lg:w-full lg:max-w-[416px] inset-0 ${isShow || isDesktop ? "z-[22] lg:z-[13]" : "z-[-1]"} h-[100dvh]`}
    >
      <RemoveScroll
        enabled={!isDesktop ? isShow : false}
        className="h-[calc(100dvh-58px)] lg:h-[100dvh] relative"
      >
        <div className="absolute bottom-[0] lg:top-0 lg:right-0 lg:h-[100dvh] lg:max-w-[416px] bg-[black] text-white w-full">
          <button
            onClick={handleClose}
            className="absolute top-[-30px] right-0 flex items-center gap-[5px] mr-[10px]"
          >
            <div>Close</div>
            <div
              style={{ background: "var(--cta_2)" }}
              className="flex items-center w-[18px] h-[18px] rounded-[50%] text-text-3 text-[12px] leading-[12px] overflow-hidden"
            >
              <div className="w-[12px] h-[12px] mx-auto text-center">x</div>
            </div>
          </button>
          <div className="hidden uppercase lg:block pt-[60px] pb-[48px] px-[44px] text-[32px] leading-[36px] text-center text-text-4 font-bold max-w-[328px] mx-auto font-font-family-3">
            Customize Your Poster
          </div>
          <ul className="pt-[8px] flex items-center lg:pb-[15px] lg:border-b-[1px] border-text-5">
            {menus.map((menu) => {
              return (
                <li className="grow-[1] relative" key={menu}>
                  <CustomizeTabIndicator isActive={selectedMenu === menu} />
                  <CustomizeTabMenuBtn
                    handleMenu={handleMenu}
                    isActive={selectedMenu === menu}
                  >
                    {menu}
                  </CustomizeTabMenuBtn>
                </li>
              )
            })}
          </ul>
          <div
            className={cn(
              "p-[30px] pb-[42px] lg:mt-[24px] max-w-[300px] lg:mx-auto lg:p-[0]",
              selectedMenu === menus[2]
                ? "max-w-none lg:max-w-[310px] p-[20px] lg:pt-[16px]"
                : ""
            )}
          >
            {selectedMenu === menus[0] ? (
              <ul className="flex items-center lg:justify-center gap-[25px]">
                {templates.map((template) => {
                  return handleRenderTemplate(template)
                })}
              </ul>
            ) : null}
            {selectedMenu === menus[1] ? (
              <div>
                <ul className="flex items-center gap-[25px] lg:justify-center">
                  {fonts.map((font) => {
                    return handleRenderFont(font)
                  })}
                  <li className="max-h-[50px] flex flex-col">
                    <SlideRadio
                      value={isUnderline}
                      handleChange={handleUnderline}
                    />
                    <div className="text-[16px] leading-[24px]">Underline</div>
                  </li>
                </ul>
              </div>
            ) : null}
            {selectedMenu === menus[2] ? (
              <>
                {String(selectedCat).toLowerCase() !== "no category" ? (
                  <SwiperComp
                    ref={catSwiperRef}
                    slidesPerView={"auto"}
                    spaceBetween={10}
                    className="mb-[30px] lg:mb-[20px]"
                  >
                    {imageCats.map((cat, index) => handleImageCats(cat, index))}
                  </SwiperComp>
                ) : null}
                {isDesktop ? null : (
                  <SwiperComp
                    ref={swiperRef}
                    slidesPerView={"auto"}
                    spaceBetween={25}
                  >
                    {imageToRender
                      .filter((obj) => obj.category === selectedCat)[0]
                      ?.img_list.map((image, index) => {
                        return handleRenderImages(image, true, index)
                      })}
                  </SwiperComp>
                )}
                <ul className="hidden lg:flex flex-wrap gap-[15px] list-none">
                  {imageToRender
                    .filter((obj) => obj.category === selectedCat)[0]
                    ?.img_list.map((image, index) => {
                      return handleRenderImages(image, false, index)
                    })}
                </ul>
              </>
            ) :
            null}
          </div>
          <ButtonAction
            className="hidden lg:block grow-[1] mt-[17%] max-w-[300px] mx-auto"
            intent={isActive ? "primary" : "primary_disable"}
            onClick={handleSubmit}
            disabled={isActive ? false : true}
          >
            {isSubmitting ? "Loading..." : "Submit"}
          </ButtonAction>
          {isPosterExist ? (
            <div className="hidden lg:flex mt-[6%] mb-[4.6%] max-w-[363px] mx-auto p-[20px] rounded-[6px] bg-bg-2 border-[1px] border-text-5 flex items-center gap-[10px]">
              <div className="text-[14px] leading-[20px] max-w-[170px] text-text-4">
                Penasaran sama kreasi mereka yang udah ikutan? Lihat galerinya
                di sini
              </div>
              <Link
                href={"/submission-gallery/br?tab=Galeri%20Poster&showToast=true"}
                className="inline-block"
              >
                <ButtonAction
                  innerClass="rounded-[19px]"
                  className="max-w-[143px] mx-auto rounded-[20px] !text-[14px] !leading-[20px]"
                  intent={"secondary"}
                >
                  <div className="flex items-center justify-center gap-[8px] ">
                    <div className="w-[20px] h-[20px] relative">
                      <Image
                        src={`/assets/${process.env.NEXT_PUBLIC_NAME}/ugc/ribbon.svg`}
                        alt="ribbon"
                        className="absolute inset-0"
                      />
                    </div>
                    Lihat Gallery
                  </div>
                </ButtonAction>
              </Link>
            </div>
          ) : null}
        </div>
      </RemoveScroll>
    </motion.div>
  )
}

export default CustomizeTab
