"use client";
import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { Image } from "@/components/shared/image/image";
import ButtonBack from "../shared/button-back/button-back";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import { Description } from "../shared/description/description";
import { useDropzone } from "react-dropzone";
import { RemoveScroll } from "react-remove-scroll";
import { useDatalayer } from "@/hooks/use-datalayer";
import { ClientApiList } from "@/lib/api/client-service";
import ButtonAction from "../shared/button-action/button-action";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useWindowSize } from "@/hooks/use-window-size";
import { getArticlePathname } from "@/hooks/use-pathname";
import UseQueryParams from "@/hooks/use-query-params";
import { Spinner } from "../shared/spinner";
import { getIOSSemever } from "@/lib/utils";
import { PopupSubmission } from "./popup-submission/popup-submission";

const GameSumbission = (props) => {
  const QUERYPARAM = "point";
  const { handlePushQuery } = UseQueryParams();
  const captchaRef = useRef();
  const {
    stage,
    setStage,
    max_char = 140,
    max_size = 26000000, //26mb
    data = {},
    game = {},
  } = props;

  const { width } = useWindowSize();
  const isDesktop = width >= 1024;
  const datalayer = useDatalayer();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const { replace } = useRouter();

  const [isShowing, setIsShowing] = useState(true);
  const [success, setSuccess] = useState(false);
  const [files, setFiles] = useState([]);
  const [preview, setPreview] = useState(false);
  const [filesView, setFilesView] = useState([]);
  const [img, setImg] = useState([]);
  const [errors, setErrors] = useState("");
  const [point, setPoint] = useState(false);
  const [caption, setCaption] = useState("");
  const [char, setChar] = useState(0);
  const [nextUrl, setNextUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [popupData, setPopupData] = useState({});

  const imageType = {
    "image/jpeg": [],
    "image/png": [],
  };

  const videoType = {
    "video/mp4": [],
    "video/x-matroska": [],
    "video/quicktime": [],
  };

  const submissionType =
    data?.submission_type == "image" ? imageType : videoType;

  const formatDetail = `Maksimal ${game?.amount_of_submission} ${data?.submission_type == "image" ? "foto" : "video"}. Format ${data?.submission_type == "image" ? "gambar .jpg .jpeg .png" : "video .mp4 .mkv .mov"}  dan ukuran maksimum 500 x 373px atau 26MB`;

  const bgImg = isDesktop
    ? game?.desktop_img_url_file
    : game?.mobile_img_url_file;

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: game?.amount_of_submission - files?.length,
    maxSize: max_size,
    accept: submissionType,
    onDrop: async (acceptedFiles, fileRejections) => {
      const formattedFiles = [];

      for (const file of acceptedFiles) {
        const createObjectURL =
          window.webkitURL.createObjectURL || window.URL.createObjectURL;

        const result = Object.assign(file, {
          preview: createObjectURL(new Blob([file], { type: file.type })),
        });

        formattedFiles.push(result);
      }

      setFiles(files.concat(formattedFiles));
      setImg(img.concat(acceptedFiles));

      // error upload file handling
      if (fileRejections.length === 0) {
        setErrors("");
      } else {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            console.log("err", err);
            if (err.code === "file-too-large") {
              setErrors(
                `Ukuran ${data?.submission_type == "image" ? "foto" : "video"} terlalu besar`
              );
            } else if (err.code === "file-invalid-type") {
              setErrors(`Format file tidak sesuai`);
            } else if (err.code === "too-many-files") {
              setErrors(
                `${data?.submission_type == "image" ? "Foto" : "Video"} tidak boleh lebih dari ${game?.amount_of_submission}`
              );
            }
          });
        });
      }
    },
  });

  const removeFile = (file) => {
    const resultFile = files.filter((media) => media.preview !== file.preview);
    return setFiles(resultFile);
  };
  const removeImg = (file) => {
    const resultImg = files.filter((media) => media.preview !== file.preview);
    return setImg(resultImg);
  };

  const isIOSOver17 = getIOSSemever()?.major >= 17;

  const thumbs = files.map((file, index) => (
    <div className="relative w-fit h-fit" key={file.preview}>
      <div
        className={clsx(
          "relative rounded-[10px] overflow-hidden",
          isDesktop ? "w-[88px] h-[88px]" : "w-[80px] h-[80px]"
        )}
      >
        <button
          className="absolute top-[3px] right-[3px] z-[1] w-[24px]"
          onClick={() => {
            removeFile(file);
            removeImg(file);
          }}
        >
          <IconCloseSquare />
        </button>
        <button
          data-testid="preview-btn"
          className={clsx(
            "relative rounded-[10px] overflow-hidden",
            isDesktop ? "w-[88px] h-[88px]" : "w-[80px] h-[80px]"
            // isIOSOver17
            //   ? "rounded-[10px] border-[1px] border-[#D7D8D9] overflow-hidden flex flex-col justify-center items-center p-[11px]"
            //   : ""
          )}
          onClick={() => {
            setFilesView(
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            );
            setPreview(true);
          }}
        >
          {data?.submission_type == "image" ? (
            <Image
              data-testid={`thumb-preview-${index}`}
              src={file.preview}
              alt="pic"
              /* Revoke data uri after image is loaded */
              onLoad={() => {
                URL.revokeObjectURL(file.preview);
              }}
            />
          ) : (
            <>
              {isIOSOver17 ? (
                <IOSVideo
                  src={file.preview}
                  type={file.type}
                  onLoad={() => {
                    URL.revokeObjectURL(file.preview);
                  }}
                />
              ) : (
                <video
                  onLoad={() => {
                    URL.revokeObjectURL(file.preview);
                  }}
                  src={file.preview}
                ></video>
              )}
            </>
          )}
        </button>
      </div>
      {preview && (
        <div className="fixed top-[0] z-[20] bg-black/[.75] bottom-0 right-0 left-0 flex justify-center items-center">
          <RemoveScroll className="w-full h-screen">
            <div className="absolute w-full max-w-[690px] px-[15px] top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2">
              <div className="relative w-full aspect-[345/257] rounded-[10px] overflow-hidden flex justify-center items-center">
                {data?.submission_type == "image" ? (
                  <Image
                    alt="preview"
                    data-testid="preview"
                    src={filesView.preview}
                    style={{ objectFit: "cover" }}
                    /* Revoke data uri after image is loaded */
                    onLoad={() => {
                      URL.revokeObjectURL(filesView.preview);
                    }}
                  />
                ) : isIOSOver17 ? (
                  <IOSVideo
                    controls
                    src={filesView.preview}
                    type={filesView.type}
                    onLoad={() => {
                      URL.revokeObjectURL(filesView.preview);
                    }}
                  />
                ) : (
                  <video
                    controls
                    onLoad={() => {
                      URL.revokeObjectURL(filesView.preview);
                    }}
                    src={filesView.preview}
                  />
                )}
              </div>
              <button
                className="absolute top-[-53px] right-[15px] w-[50px] h-[50px] text-white"
                onClick={() => setPreview(false)}
              >
                <IconClose />
              </button>
            </div>
          </RemoveScroll>
        </div>
      )}
    </div>
  ));

  const onSubmit = async () => {
    setLoading(true);
    captchaRef.current.reset();
    const token = await captchaRef.current.executeAsync();
    if (!token) {
      setErrors("Captcha harus diisi");
      replace(pathname);
      return;
    } else {
      setErrors("");
      const { data: submitData } = await ClientApiList.getAnswerFile({
        engagement_uuid: data?.engagement_uuid,
        type: data?.submission_type,
        submission: files,
        caption: caption,
        "g-recaptcha-response": token,
      });

      if (submitData) {
        datalayer.push({
          event: "general_event",
          event_name: "click_kirim_submission_file",
          feature: "simple engagement",
          engagement_type: "submission",
          engagement_name: `${data?.submission_type?.toLowerCase()}`,
          campaign_name: data?.campaign_name?.toLowerCase(), // e.g all
          event_label: `submission ${data?.submission_type?.toLowerCase()} - ${data?.subtitle_introduction?.toLowerCase()}`,
          level: 1,
          content_id: data?.engagement_uuid,
        });

        const { data: dataStatus } = await ClientApiList.getGameConfirmation({
          engagement_uuid: data?.engagement_uuid,
          type: "media-submission",
          status: "completed",
        });

        if (dataStatus) {
          setPopupData({ ...dataStatus.data.data });
          setPoint(data?.point);
          setSuccess(true);

          datalayer.push({
            event: "general_event",
            event_name: `page_reached_finish_on_${data?.submission_type?.toLowerCase()}`,
            feature: "simple engagement",
            engagement_type: "submission",
            engagement_name: `${data?.submission_type?.toLowerCase()}`,
            campaign_name: data?.campaign_name?.toLowerCase(), // e.g all
            event_label: `submission ${data?.submission_type?.toLowerCase()} - ${data?.subtitle_introduction?.toLowerCase()}`,
            character_result:
              dataStatus.data.data.subtitle_confirmation?.toLowerCase(),
            level: 1,
            content_id: data?.engagement_uuid,
          });
          datalayer.push({
            event: "general_event",
            event_name: `page_reached_success_submit_${data?.submission_type?.toLowerCase()}`,
            feature: "simple engagement",
            engagement_type: "submission",
            engagement_name: `${data?.submission_type?.toLowerCase()}`,
            campaign_name: data?.campaign_name?.toLowerCase(), // e.g all
            event_label: `submission ${data?.submission_type?.toLowerCase()} - ${data?.subtitle_introduction?.toLowerCase()}`,
            character_result:
              dataStatus.data.data.subtitle_confirmation?.toLowerCase(),
            level: 1,
            content_id: data?.engagement_uuid,
          });

          if (submitData?.data?.result?.data?.isChances) {
            handlePushQuery(false, [QUERYPARAM, true]);

            if (searchParams.get("page_uuid")) {
              const { data: nextUrlData } =
                await ClientApiList.getNextChallenge({
                  page_uuid: searchParams.get("page_uuid"),
                  mission_order: parseInt(searchParams.get("index")),
                });
              if (
                Object.keys(nextUrlData.data.data).length === 0 &&
                nextUrlData.data.data.constructor === Object
              ) {
                setNextUrl(searchParams.get("campaign"));
              } else {
                const nextMissionUrl = getArticlePathname({
                  ...nextUrlData.data.data,
                });
                setNextUrl(
                  `${nextMissionUrl}?page_uuid=${searchParams.get("page_uuid")}&index=${parseInt(searchParams.get("index")) + 1}&campaign=${searchParams.get("campaign")}${submitData?.data?.result?.data?.point ? `&${QUERYPARAM}=true` : ""}`
                );
              }
            }
          }
          return;
        }
      }
    }
    setLoading(false);
  };

  const handleDisabledButton = () => {
    return files.length == 0 || char == 0 || char > max_char || !game?.chances;
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [stage]);

  return (
    <>
      {stage != 1 && (
        <div
          className={clsx(
            "relative w-full h-full min-h-[715px] text-text-2",
            isDesktop && "mb-[112px]"
          )}
        >
          <div
            className={clsx(
              "absolute z-1 overflow-hidden",
              isDesktop
                ? "aspect[1410/520] left-[15px] right-[15px] rounded-[15px]"
                : "top-[-20px] left-0 right-0 h-[715px] w-[100%] rounded-b-[10px] "
            )}
          >
            {bgImg && (
              <Image
                data-testid="bg-img"
                alt="cover"
                objectFit="cover"
                fill
                src={bgImg}
                // src="https://dummyimage.com/1410x520/fff/000"
              />
            )}
          </div>
          <ButtonBack
            className="top-[-10px] left-[6px]"
            onClick={() => {
              setStage(1);
              setFiles([]);
              setCaption("");
            }}
          />
          <div
            className={clsx(
              "relative w-full max-w-[461px] mx-auto",
              isDesktop ? "pt-[285px]" : "pt-[216px] px-[10px]"
            )}
          >
            <div
              className="bg-bg-1 p-[15px] rounded-[10px] overflow-hidden"
              encType="multipart/form-data"
              method="post"
            >
              <h2 className="text-xl font-bold font-font-family-7">
                {game?.question}
              </h2>
              <Description
                className={clsx(
                  "text-sm font-medium font-font-family-6 my-[10px]",
                  process.env.NEXT_PUBLIC_NAME === "djisamsoe"
                    ? "text-text-2"
                    : " text-[#413F3F]"
                )}
                description={game?.question_desc}
              />
              <div className="p-[10px] pb-[15px] bg-bg-1 rounded-[10px] border-[1px] border-cta-4 overflow-hidden">
                <span
                  className={
                    "text-sm font-bold font-font-family-7 mb-[10px] block"
                  }
                >
                  Upload {data?.submission_type == "image" ? "Gambar" : "Video"}
                </span>
                <div
                  className={clsx(
                    "flex flex-wrap items-center",
                    isDesktop ? "gap-[10px]" : "gap-[5px]"
                  )}
                >
                  {files?.length > 0 ? (
                    <div
                      className={clsx(
                        "contents flex-wrap items-center",
                        isDesktop ? "gap-[10px]" : "gap-[5px]"
                      )}
                    >
                      {thumbs}
                    </div>
                  ) : (
                    <div
                      {...getRootProps({
                        className: `dropzone ${
                          isDesktop ? "w-[88px] h-[88px]" : "w-[80px] h-[80px]"
                        } rounded-[10px] border-[1px] border-dashed border-cta-1 overflow-hidden flex flex-col justify-center items-center p-[11px]`,
                      })}
                    >
                      <input
                        data-testid="drop-input"
                        {...getInputProps()}
                        onClick={() => setErrors("")}
                      />
                      <IconUpload
                        width={isDesktop ? "30" : "25"}
                        height={isDesktop ? "29" : "24"}
                      />
                      <span
                        className={clsx(
                          "text-text-1 text-center mt-[3px] font-medium font-font-family-6",
                          isDesktop
                            ? "text-sm tracking-[0.275px]"
                            : "text-xs tracking-[0.25px]"
                        )}
                      >
                        Upload{" "}
                        {data?.submission_type == "image" ? "Foto" : "Video"}
                      </span>
                    </div>
                  )}
                  {files.length >= 1 &&
                    game?.amount_of_submission > 1 &&
                    files.length < game?.amount_of_submission && (
                      <div
                        data-testid="drop-input"
                        {...getRootProps({
                          className: `dropzone ${
                            isDesktop
                              ? "w-[88px] h-[88px]"
                              : "w-[80px] h-[80px]"
                          } rounded-[10px] border-[1px] border-dashed border-cta-1 overflow-hidden flex flex-col justify-center items-center p-[11px]`,
                        })}
                      >
                        <input data-testid="drop-input" {...getInputProps()} />
                        <IconUpload
                          width={isDesktop ? "30" : "25"}
                          height={isDesktop ? "29" : "24"}
                        />
                        <span
                          className={clsx(
                            "text-text-1 text-center mt-[3px] font-medium font-font-family-6",
                            isDesktop
                              ? "text-sm tracking-[0.275px]"
                              : "text-xs tracking-[0.25px]"
                          )}
                        >
                          Upload{" "}
                          {data?.submission_type == "image" ? "Foto" : "Video"}
                        </span>
                      </div>
                    )}
                </div>
                <p
                  className={clsx(
                    "font-medium font-font-family-6 mt-[10px]",
                    isDesktop ? "text-sm" : "text-xs",
                    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
                      ? "text-text-2"
                      : " text-[#413F3F]"
                  )}
                >
                  {formatDetail}
                </p>
                {errors && (
                  <div className="w-full flex items-center gap-[5px] rounded-[5px] text-xs text-text-1 bg-cta-3 mt-[10px] p-[10px] tracking-[0.25px]">
                    <IconErrors />
                    <span>{errors}</span>
                  </div>
                )}
              </div>
              <div
                className={clsx(
                  "p-[10px] pb-[15px] bg-bg-1 rounded-[10px] border-[1px] border-cta-4 overflow-hidden",
                  isDesktop ? "my-[20px]" : "my-[10px]"
                )}
              >
                <span className={"text-sm font-medium font-font-family-6"}>
                  Caption
                </span>
                <div className="w-full mt-[10px] p-[10px] bg-bg-3 border border-cta-4 rounded-[5px]">
                  <textarea
                    className="w-full text-sm placeholder-text-3 bg-bg-3"
                    placeholder={`Tulis caption ${
                      process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "mu" : "lo"
                    } di sini`}
                    rows={6}
                    onChange={(e) => setCaption(e.target.value)}
                    value={caption}
                    onKeyUp={(e) => setChar(e.target.value.length)}
                  />
                  <div className="flex justify-end w-full text-xs text-text-3">
                    <span>{char}/</span>
                    {max_char}
                    <span> karakter</span>
                  </div>
                </div>
              </div>
              {loading ? (
                <div className="w-full flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                <ButtonAction
                  data-testid="submit-btn"
                  className="py-[15px] px-[20px]"
                  intent={
                    handleDisabledButton() ? "primary_disable" : "primary"
                  }
                  disabled={handleDisabledButton()}
                  onClick={() => onSubmit()}
                >
                  Kirim
                </ButtonAction>
              )}
              <ReCAPTCHA
                ref={captchaRef}
                size="invisible"
                sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA}
              />
            </div>
          </div>

          {success && (
            <PopupSubmission
              nextUrl={nextUrl}
              popupData={popupData}
              point={point}
              isOpen={isShowing}
              onClickBtnX={() => {
                setFiles([]);
                setCaption("");
                setIsShowing(false);
                setStage(1);
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default GameSumbission;

GameSumbission.propTypes = {
  stage: PropTypes.number,
  setStage: PropTypes.func,
  image_url: PropTypes.string,
  max_char: PropTypes.number,
  amount_of_submission: PropTypes.number,
  max_size: PropTypes.number,
  type_file: PropTypes.string,
  data: PropTypes.object,
};

const IconUpload = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M9.98047 13L12.9805 9L15.9805 13.5V12H19.9805V5C19.9805 3.897 19.0835 3 17.9805 3H4.98047C3.87747 3 2.98047 3.897 2.98047 5V17C2.98047 18.103 3.87747 19 4.98047 19H12.9805V15H5.98047L8.98047 11L9.98047 13Z"
        className="fill-icon-1"
      />
      <path
        d="M19.9805 14H17.9805V17H14.9805V19H17.9805V22H19.9805V19H22.9805V17H19.9805V14Z"
        className="fill-icon-1"
      />
    </svg>
  );
};

IconUpload.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconClose = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <circle
        cx="24"
        cy="24"
        r="19.75"
        fill="url(#paint0_linear_4840_16586)"
        stroke="url(#paint1_linear_4840_16586)"
        strokeWidth="0.5"
      />
      <path
        d="M16.3891 16.4237C16.6386 16.1751 16.9768 16.0354 17.3294 16.0354C17.682 16.0354 18.0202 16.1751 18.2697 16.4237L23.9823 22.1243L29.6948 16.4237C29.8575 16.2489 30.0643 16.1212 30.2936 16.0539C30.5229 15.9866 30.7662 15.9822 30.9977 16.0413C31.2293 16.1004 31.4406 16.2207 31.6094 16.3896C31.7782 16.5585 31.8982 16.7696 31.9568 17.0009C32.0161 17.2317 32.012 17.4742 31.9448 17.7028C31.8777 17.9315 31.7501 18.1379 31.5754 18.3003L25.8628 24.0009L31.5754 29.7014C31.7505 29.8637 31.8786 30.0701 31.946 30.2989C32.0134 30.5278 32.0178 30.7705 31.9586 31.0016C31.8994 31.2326 31.7788 31.4435 31.6096 31.6119C31.4404 31.7804 31.2287 31.9001 30.997 31.9586C30.7657 32.0178 30.5227 32.0136 30.2936 31.9467C30.0644 31.8797 29.8576 31.7523 29.6948 31.578L23.9823 25.8774L18.2697 31.578C18.0173 31.8124 17.6837 31.94 17.3389 31.9341C16.9942 31.9282 16.6652 31.7891 16.4211 31.5461C16.1776 31.3025 16.0382 30.9742 16.0323 30.6302C16.0263 30.2862 16.1542 29.9533 16.3891 29.7014L22.1017 24.0009L16.3891 18.3003C16.14 18.0513 16 17.7139 16 17.362C16 17.0102 16.14 16.6727 16.3891 16.4237Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4840_16586"
          x1="50.5"
          y1="-1.5"
          x2="13.5833"
          y2="41.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.302701" stopColor="white" stopOpacity="0.471684" />
          <stop offset="1" stopColor="white" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4840_16586"
          x1="6.5"
          y1="41"
          x2="41"
          y2="6.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.735223" stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0.55" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const IconCloseSquare = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M0.980469 5C0.980469 2.23858 3.21904 0 5.98047 0H19.9805C22.7419 0 24.9805 2.23858 24.9805 5V19C24.9805 21.7614 22.7419 24 19.9805 24H5.98047C3.21904 24 0.980469 21.7614 0.980469 19V5Z"
        fill="#5F6368"
        fillOpacity="0.25"
      />
      <path
        d="M8.73828 7.75732L17.2236 16.2426"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.73738 16.2426L17.2227 7.75732"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const IconErrors = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect x="5" y="5" width="10" height="10" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.61326 2.18848C7.28174 2.1879 6.96357 2.31904 6.72874 2.55304L2.8811 6.38721C2.64627 6.62122 2.51402 6.93893 2.51343 7.27045L2.50391 12.7023C2.50333 13.0338 2.63447 13.352 2.86847 13.5868L6.70264 17.4345C6.93665 17.6693 7.25436 17.8016 7.58588 17.8021L13.0177 17.8117C13.3493 17.8122 13.6674 17.6811 13.9023 17.4471L17.7499 13.6129C17.9847 13.3789 18.117 13.0612 18.1176 12.7297L18.1271 7.29783C18.1277 6.96631 17.9965 6.64814 17.7625 6.41331L13.9284 2.56567C13.6944 2.33084 13.3766 2.19859 13.0451 2.198L7.61326 2.18848ZM7.49432 6.09382L6.40925 7.17889L9.23043 10.0001L6.40925 12.8213L7.49432 13.9063L10.3155 11.0851L13.1367 13.9063L14.2218 12.8213L11.4006 10.0001L14.2218 7.17889L13.1367 6.09382L10.3155 8.915L7.49432 6.09382Z"
        fill="#FD2A29"
      />
    </svg>
  );
};

function IOSVideo({ src, type, ...props }) {
  const ref = useRef();

  useEffect(() => {
    const video = ref.current;
    if (video) {
      video.load();
    }
  }, []);

  return (
    <video ref={ref} {...props}>
      <source src={src} type={type} />
    </video>
  );
}
