"use client"
import React from "react"
import PropTypes from "prop-types"
import { Image } from "@/components/shared/image/image"
import { urlAsset } from "@/helper/utils";

export default function HistoryCardProgress({
  title,
  icon,
  label,
  current,
  total,
  duration,
  bg,
}) {
  return (
    <div className="min-h-[90px] max-h-[90px] lg:max-w-none lg:max-h-none lg:min-h-[128px] relative bg-bg-1 p-[10px] lg:p-0 rounded-[10px] grow-[1] lg:flex items-center justify-self-stretch overflow-hidden max-w-[353px]">
      <Image
        data-testid="history-card-progress-bg"
        objectFit="cover"
        className="hidden lg:block absolute inset-0 z-[0] object-left"
        src={`${urlAsset()}/mgm/${bg}`}
        alt="bg"
      />
      {/* <div className="relative z-[1] hidden lg:block w-[128px] h-[128px] bg-bg-1"> */}
      {/* <Image
          alt="point"
          data-testid="history-card-progress-img"
          src={
            title === "Total Poin Terkumpul"
              ? "/assets/amild/mgm/dart.png"
              : "/assets/amild/mgm/chart.png"
          }
        /> */}
      {/* </div> */}
      <div className="relative z-[1] lg:pl-[128px]">
        <div
          data-testid="history-card-progress-title"
          className="text-[14px] text-text-2 font-medium font-font-family-6 leading-[20px] mb-[5px] lg:mb-[10px]"
        >
          {title}
        </div>
        {icon && label ? (
          <div className="flex gap-[5px] items-center mb-[5px] lg:mb-[10px]">
            <div className="w-[24px] h-[24px]">
              {title === "Total Poin Terkumpul" ? (
                <Image
                  alt="point"
                  src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/reward-profile.svg`}
                />
              ) : null}
            </div>
            <div
              data-testid="history-card-progress-label"
              className="text-[14px] leading-[20px] font-bold font-font-family-7 text-text-1"
            >
              {label}
            </div>
          </div>
        ) : (
          <div
            data-testid="history-card-progress-count"
            className="text-[14px] font-bold font-font-family-7 leading-[20px] mb-[5px] lg:mb-[10px] text-text-1"
          >
            + {total} Teman Ikutan
          </div>
        )}
        <div
          data-testid="history-card-progress-duration"
          className="text-[12px] lg:leading-[16px] tracking-[0.06px] italic font-medium font-font-family-6 text-[#A6A6A6]"
        >
          {duration}
        </div>
      </div>
    </div>
  )
}

HistoryCardProgress.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  current: PropTypes.number,
  total: PropTypes.number,
  duration: PropTypes.string,
}
