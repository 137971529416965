"use client"
import React, { Suspense, useEffect, useState } from "react"
import { Image } from "@/components/shared/image/image"
import Link from "@/components/shared/link/link"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
// custom comp. imports
import ButtonAction from "../shared/button-action/button-action"
import CampaignCard from "../campaign-intro/campaign-card/campaign-card"
import CampaignTitle from "../campaign-intro/campaign-card/components/campaign-title"
import CampaignDescription from "../campaign-intro/campaign-card/components/campaign-description"
import { usePathname, useSearchParams } from "next/navigation"
import PopupGame from "../shared/popup-game/popup-game"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { useDatalayer } from "@/hooks/use-datalayer"
import { useInView } from "react-intersection-observer"
import ButtonBack from "../shared/button-back/button-back"
import CampaignPoint from "../campaign-intro/campaign-card/components/campaign-point"
import { SuccessIcon } from "../sections/article-details"
import CheckBox from "../edit-profile/checkbox"
import { useForm } from "react-hook-form"
import { ClientApiList } from "@/lib/api/client-service"
import DOMPurify from "isomorphic-dompurify"
import { useRef } from "react"
import { urlAsset } from "@/helper/utils";

const variants = {
  show: {
    opacity: 1,
    y: 0,
    display: "block",
    transition: {
      ease: "easeInOut",
      duration: 0.3,
    },
  },
  hide: {
    opacity: 0,
    y: "-100px",
    display: "none",
    transition: {
      ease: "easeInOut",
      duration: 0.3,
      display: {
        delay: 0.3,
        duration: 0.1,
      },
    },
  },
}

function PuzzleIntroFallback() {
  return <div>PuzzleIntroFallback</div>
}

function SuspendedPuzzleIntro({
  cta_label,
  campaign_name,
  isLoading,
  setIsStart,
  ...props
}) {
  const linkRef = useRef(null)
  const init = useRef(false)
  const searchParams = useSearchParams()
  const isMobile = useIsMobile()
  const pathname = usePathname()
  const [hideRule, setHideRule] = useState(false)
  const [isShowPopup, setIsShowPopup] = useState(false)
  const datalayer = useDatalayer()

  const { register, watch, setValue } = useForm({
    isAgree: false,
  })

  useEffect(() => {
    if (props.tncValue && !init.current) {
      setValue("isAgree", props.tncValue)
      init.current = true
    }
  }, [props])

  useEffect(() => {
    if (
      linkRef.current &&
      props?.tncText &&
      props?.tncText.includes("target=_blank") &&
      linkRef.current.querySelector("a") &&
      !String(linkRef.current?.querySelector("a")).includes("target=_blank")
    ) {
      linkRef.current?.querySelector("a")?.setAttribute("target", "_blank")
      linkRef.current?.querySelector("a")?.setAttribute("rel", "noopener")
    }
  })

  const [landingRef, inView] = useInView({
    triggerOnce: true,
  })

  const hrefParse = (value = true) => {
    const current = new URLSearchParams(Array.from(searchParams.entries())) // -> has to use this form

    return {
      pathname,
      query: {
        ...Object.fromEntries(current.entries()),
        "hide-puzzle-rule": value,
      },
    }
  }

  const handleDataLayer = () => {
    datalayer.push({
      event: "general_event",
      event_name: "click_mulai_sekarang_puzzle",
      feature: "simple engagement",
      engagement_type: "games",
      engagement_name: "puzzle",
      campaign_name: campaign_name.toLowerCase(), // e.g all
      event_label: `puzzle - ${props.subtitle_introduction.toLowerCase()}`,
      level: 1,
      content_id: props.engagement_uuid,
    })
  }

  const datalayerView = async () => {
    datalayer.push({
      event: "general_event",
      event_name: "page_reached_on_simple_engagement",
      feature: "simple engagement",
      page_type: "simple engagement",
      campaign_name: campaign_name.toLowerCase(), // e.g all
      // userld: dataProfile?.data.data.dataProfile,
      event_label: `puzzle - ${props.subtitle_introduction.toLowerCase()}`,
      content_id: props.engagement_uuid,
    })
  }

  const handleBtnAria = () => {
    return props.isTNC ? props.isTNC && !watch("isAgree") : false
  }

  const handleBtnIndex = () => {
    if (!props.isTNC) return undefined
    else return props.isTNC && !watch("isAgree") ? -1 : undefined
  }

  const handleBtnIntent = () => {
    if (!props.isTNC) return "primary"
    else return watch("isAgree") ? "primary" : "primary_disable"
  }

  const handleBtnStyleOnDisabled = () => {
    if (!props.isTNC) return "cursor-pointer"
    else
      return props.isTNC && !watch("isAgree")
        ? "pointer-events-none cursor-not-allowed"
        : "cursor-pointer"
  }

  const handleTNC = async () => {
    try {
      const { data } = await ClientApiList.setTncEngagement({
        engagement_uuid: props.engagement_uuid,
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (inView) {
      datalayerView()
    }
    // eslint-disable-next-line
  }, [inView])

  useEffect(() => {
    setHideRule(searchParams.get("hide-puzzle-rule") === "true")
  }, [searchParams])

  return (
    <motion.div
      className="relative py-[20px]  px-[9px]"
      key="animation-on-state"
      variants={variants}
      animate={!hideRule ? "show" : "hide"}
    >
      <ButtonBack isEngagament={true} />
      <div ref={landingRef} className="md:flex">
        <div className="text-center text-[20px] text-text-2 font-medium font-font-family-6 leading-[27px] mb-[30px] md:hidden">
          Puzzle Challenges
        </div>

        <div className="rounded-[10px] md:rounded-[0] md:rounded-l-[20px] overflow-hidden mb-[10px] md:mb-[0] bg-bg-5 w-[100%] max-w-[604px] left-0 right-0 min-h-[357px] relative">
          <Image
            alt="cover"
            className="object-center absolute"
            src={
              isMobile
                ? props.mobile_image_introduction_url
                : props.desktop_image_introduction_url
            }
          />
        </div>
        <CampaignCard className="border-cta-4 text-text-2 !bg-bg-3 mb-[10px] md:mb-0 md:min-h-[604px] md:flex flex-cols items-center lg:rounded-[0] md:!rounded-r-[20px] md:!px-[50px] w-full">
          <div className="flex gap-[20px] items-center">
            <div
              style={{ background: "var(--cta_3)" }}
              className="hidden w-fit lg:block text-[14px] font-medium font-font-family-6 tracking-[0.14px] leading-[10px] text-text-1 bg-cta-3 py-[10px] px-[15px] rounded-[10px] mb-[20px]"
            >
              Puzzle Challenge
            </div>
            {!isMobile && props.point > 0 && (
              <CampaignPoint
                className="hidden lg:flex"
                asset={
                  !props?.finished &&
                  `/assets/${process.env.NEXT_PUBLIC_NAME}/icons/${process.env.NEXT_PUBLIC_NAME === "amild" ? "reward-profile-effect.png" : process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "reward.png" : "reward-profile.svg"}`
                }
                icon={
                  props?.finished && <SuccessIcon className="hidden lg:block" />
                }
              >
                {props.point}
              </CampaignPoint>
            )}
          </div>
          <CampaignTitle className="lg:!text-[40px] lg:!leading-[44px] leading-[24px] mb-[10px] lg:mb-[20px] lg:text-[40px] font-font-family-7">
            {props.subtitle_introduction}
          </CampaignTitle>
          {isMobile && props.point > 0 && (
            <CampaignPoint
              className="lg:hidden flex"
              asset={
                !props?.finished &&
                `/assets/${process.env.NEXT_PUBLIC_NAME}/icons/${process.env.NEXT_PUBLIC_NAME === "amild" ? "reward-profile-effect.png" : process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "reward.png" :"reward-profile.svg"}`
              }
              icon={
                props?.finished && <SuccessIcon className="lg:hidden block" />
              }
            >
              {props.point}
            </CampaignPoint>
          )}
          <CampaignDescription className="mb-0 md:mb-[20px] lg:text-[20px] leading-[20px] lg:leading-[28px]">
            {props.desc_introduction}
          </CampaignDescription>
          {props.isTNC ? (
            <form
              method="POST"
              className="flex gap-[5px] mt-[10px] md:mb-[30px]"
            >
              <CheckBox
                register={register}
                name={"isAgree"}
                disabled={props.tncValue}
                isCenter={true}
                className="top-[calc(50%_-_10px)]"
              />
              <div
                ref={linkRef}
                className="text-[12px] lg:text-[14px] font-medium underline leading-[18px] font-medium text-text-1"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(props.tncText),
                }}
              ></div>
            </form>
          ) : null}
          <ButtonAction
            onClick={() => setIsShowPopup(true)}
            intent="secondary"
            className="lg:py-[9px] lg:px-[20px] lg:text-[14px] lg:leading-[20px] hidden md:flex items-center gap-[5px] w-fit mb-[30px]"
          >
            <div className="flex items-center gap-[5px]">
              <div className="w-[20px] h-[20px]">
                {/* <Image
                  alt="rules"
                  objectFit="contain"
                  src={"/assets/amild/icons/circle-add.svg"}
                /> */}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="vuesax/bold/add-circle">
                    <g id="add-circle">
                      <path
                        style={{ fill: "var(--icon_1)" }}
                        className="fill-icon-1"
                        id="Vector"
                        d="M10.0001 1.66675C5.40841 1.66675 1.66675 5.40841 1.66675 10.0001C1.66675 14.5917 5.40841 18.3334 10.0001 18.3334C14.5917 18.3334 18.3334 14.5917 18.3334 10.0001C18.3334 5.40841 14.5917 1.66675 10.0001 1.66675ZM13.3334 10.6251H10.6251V13.3334C10.6251 13.6751 10.3417 13.9584 10.0001 13.9584C9.65842 13.9584 9.37508 13.6751 9.37508 13.3334V10.6251H6.66675C6.32508 10.6251 6.04175 10.3417 6.04175 10.0001C6.04175 9.65842 6.32508 9.37508 6.66675 9.37508H9.37508V6.66675C9.37508 6.32508 9.65842 6.04175 10.0001 6.04175C10.3417 6.04175 10.6251 6.32508 10.6251 6.66675V9.37508H13.3334C13.6751 9.37508 13.9584 9.65842 13.9584 10.0001C13.9584 10.3417 13.6751 10.6251 13.3334 10.6251Z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <span>Game Rules</span>
            </div>
          </ButtonAction>
          <div className="hidden md:block h-[1px] w-full bg-[#E6E6E6] mb-[30px]"></div>
          <Link
            aria-disabled={isLoading || handleBtnAria()}
            tabIndex={isLoading || handleBtnIndex()}
            className={`hidden md:block max-w-[343px] ${handleBtnStyleOnDisabled()}`}
            href={hrefParse()}
            onClick={() => {
              handleTNC()
              handleDataLayer()
              setIsStart(true)
            }}
          >
            <ButtonAction intent={handleBtnIntent()}>{cta_label}</ButtonAction>
          </Link>
        </CampaignCard>
        <PopupGame open={isShowPopup} onClick={() => setIsShowPopup(false)}>
          <CampaignCard className="border-[#2C2A29] md:!p-0 md:!rounded-[0] md:!border-none bg-bg-3 max-w-[436px]">
            <CampaignTitle className="md:!text-[40px] leading-[24px] md:!leading-[54px] md:mb-[20px] lg:text-[40px] font-font-family-7">
              {props.subtitle_instruction}
            </CampaignTitle>
            <div className="min-h-[133px] md:min-h-[142px] md:rounded-[20px] bg-bg-1 mb-[10px] md:mb-[15px] relative overflow-hidden">
              <Image
                alt="cover"
                objectFit="cover"
                className="!object-top absolute inset-0 w-full h-full max-w-[332px] max-h-[141px] mx-auto"
                src={`${urlAsset()}/puzzle/puzzle.png`}
              />
            </div>
            <CampaignDescription
              isList
              className="mb-0 md:!mb-0 md:!text-[16px] md:!leading-[25px] lg:text-[20px] leading-[20px] lg:leading-[28px]"
            >
              {props.desc_instruction}
            </CampaignDescription>
          </CampaignCard>
        </PopupGame>
        <CampaignCard className="border-cta-4 text-text-2 !bg-bg-3 md:hidden ">
          <CampaignTitle className="leading-[24px]">
            {props.subtitle_instruction}
          </CampaignTitle>
          <div className="min-h-[133px] bg-bg-1 mb-[10px] rounded-[15px] py-[10px] relative overflow-hidden">
            <Image
              alt="cover"
              objectFit="cover"
              className="!object-center absolute inset-0  w-full h-full mx-auto"
              src={`${urlAsset()}/puzzle/puzzle.png`}
            />
          </div>
          <CampaignDescription isList className="mb-0">
            {props.desc_instruction}
          </CampaignDescription>
        </CampaignCard>
        {!isMobile ? (
          <Link
            aria-disabled={isLoading || handleBtnAria()}
            tabIndex={isLoading || handleBtnIndex()}
            className={`md:hidden ${isLoading || handleBtnStyleOnDisabled()}`}
            onClick={() => {
              handleTNC()
              handleDataLayer()
              setIsStart(true)
            }}
            href={hrefParse()}
          >
            <ButtonAction intent={handleBtnIntent()}>{cta_label}</ButtonAction>
          </Link>
        ) : (
          <div
            style={{
              background:
                "linear-gradient(180deg,rgba(255,255,255,0), var(--background_3) 40%)",
            }}
            className="fixed z-[19] bottom-[56px] left-2/4 -translate-x-1/2 w-full px-[10px] pt-[80px] pb-[20px]"
          >
            <Link
              aria-disabled={isLoading || handleBtnAria()}
              tabIndex={isLoading || handleBtnIndex()}
              className={`md:hidden ${isLoading || handleBtnStyleOnDisabled()}`}
              onClick={() => {
                handleTNC()
                handleDataLayer()
                setIsStart(true)
              }}
              href={hrefParse()}
            >
              <ButtonAction intent={handleBtnIntent()}>
                {cta_label}
              </ButtonAction>
            </Link>
          </div>
        )}
      </div>
    </motion.div>
  )
}

export function PuzzleIntroComp(props) {
  return (
    <Suspense fallback={<PuzzleIntroFallback />}>
      <SuspendedPuzzleIntro {...props} />
    </Suspense>
  )
}

SuspendedPuzzleIntro.propTypes = {
  cta_label: PropTypes.string,
  campaign_name: PropTypes.string,
  isLoading: PropTypes.bool,
  props: PropTypes.object,
  tncValue: PropTypes.bool,
  isTNC: PropTypes.bool,
  setIsStart: PropTypes.func,
}
