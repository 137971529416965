"use client"
import clsx from "clsx"
import Style from "./slider-content.module.scss"
import Dots from "../slider-ornament.js/dots"
import Link from "@/components/shared/link/link"
import { useWindowSize } from "@/hooks/use-window-size"
import { useDatalayer } from "@/hooks/use-datalayer"
import { Image } from "@/components/shared/image/image"
import PropTypes from "prop-types"
import DOMPurify from "isomorphic-dompurify"

// const darkLogo = "/assets/iqos/logo/dark.png"
// const lightLogo = "/assets/iqos/logo/light.png"

const SliderContent = ({
  bg,
  title,
  desc,
  btn1,
  btn2,
  variant,
  itemsLength,
  activeIndex,
  shortDesc,
  textShadow,
  tagline,
  btn1Url,
  btn2Url,
}) => {
  const utm = typeof window !== "undefined" ? window.location.search : ""

  const datalayer = useDatalayer()

  const { height, width } = useWindowSize()

  // const iqosTablet = () => {
  //   if (width >= 1024) return true
  // }

  const iqosDesktop = () => {
    if (width >= 1119) return true
  }

  const totalWord = () => {
    if (width >= 1118 && height <= 650) return 32
    else return 999
  }

  const truncateText = (word, countWord = totalWord()) => {
    const wordLength = word?.split(" ").length
    if (wordLength > countWord) {
      return `${word?.split(" ", countWord).join(" ")} ...`
    } else {
      return word
    }
  }

  DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank")
      node.setAttribute("rel", "noopener")
    }
  })

  function handleDataLayer(btn) {
    datalayer.push({
      event: "generalEvent",
      event_category: "clicked on button",
      event_action: btn.toLowerCase(),
      event_label: `${title.toLowerCase()} - iqos bridging`,
      campaignName: "iqos bridging",
    })
  }

  return (
    <div className={Style.swiper_slide_container}>
      {tagline && tagline?.trim() !== "" && (
        <p
          className={clsx(
            Style.swiper_header_slogan,
            variant === "Dark" ? Style.swiper_header_slogan_dark : null
          )}
        >
          {tagline}
        </p>
      )}
      <div className={Style.swiper_slide_wrapper}>
        <div
          className={clsx(
            Style.swiper_slide_content,
            Style.swiper_slide_mainContent,
            variant === "Dark" ? Style.swiper_slide_mainContent_dark : undefined
          )}
        >
          {/* <div className={Style.swiper_slide_logo}>
            <Image
              // className={Style.logo}
              src={variant === "Light" ? lightLogo : darkLogo}
              alt="iqos logo"
              width={iqosTablet() ? "90px" : "50px"}
              height={iqosTablet() ? "90px" : "50px"}
            />
          </div> */}
          <div className={Style.swiper_slide_textWrapper}>
            {iqosDesktop() && itemsLength > 1 && (
              <Dots
                itemsLength={itemsLength}
                activeIndex={activeIndex}
                variant={variant}
              />
            )}
            <div className={Style.swiper_slide_text}>
              <p
                className={Style.swiper_slide_title}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}
              ></p>
              <div className={Style.swiper_slide_descWrapper}>
                <div
                  className={Style.swiper_slide_desc}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(truncateText(desc)),
                  }}
                />
                {textShadow === true && (
                  <div className={Style.swiper_slide_descBlur} />
                )}
              </div>
            </div>
            <div>
              {!iqosDesktop() && (
                <div
                  className={clsx(
                    Style.swiper_add_info,
                    variant === "Dark" ? Style.swiper_add_info_dark : null
                  )}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(shortDesc),
                  }}
                />
              )}
              <div className={Style.swiper_slide_button}>
                <Link href={`${btn1Url}${utm}`} passHref>
                  <button
                    className={clsx(
                      Style.swiper_btn,
                      variant === "Dark" ? Style.swiper_btn_dark : undefined
                    )}
                    onClick={() => {
                      handleDataLayer(btn1)
                    }}
                  >
                    {btn1}
                  </button>
                </Link>
                <Link href={`${btn2Url}${utm}`} passHref>
                  <button
                    className={clsx(
                      Style.swiper_btn,
                      Style.swiper_btn_b,
                      variant === "Dark" ? Style.swiper_btn_b_dark : undefined
                    )}
                    onClick={() => {
                      handleDataLayer(btn2)
                    }}
                  >
                    {btn2}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className={clsx(
            Style.swiper_slide_content,
            Style.swiper_slide_background
          )}
        >
          <Image src={bg} alt="background" layout="fill" objectFit="cover" />
        </div>
      </div>
      {iqosDesktop() && (
        <div
          className={clsx(
            Style.swiper_add_info_desktop,
            variant === "Dark" ? Style.swiper_add_info_desktop_dark : null
          )}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(shortDesc) }}
        />
      )}
    </div>
  )
}

export default SliderContent

SliderContent.propTypes = {
  bg: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  btn1: PropTypes.string,
  btn2: PropTypes.string,
  variant: PropTypes.string,
  itemsLength: PropTypes.number,
  activeIndex: PropTypes.number,
  shortDesc: PropTypes.string,
  textShadow: PropTypes.string,
  tagline: PropTypes.string,
}
