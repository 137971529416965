import React, { useEffect, useState } from "react"
import PopupAction from "../popup-action/popup-action"
import { Image } from "../image/image"
import { Description } from "../description/description"
import ButtonAction from "../button-action/button-action"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { useIsMobile } from "@/hooks/use-is-mobile"
import clsx from "clsx"
import style from "./popup-out.module.scss"
import { Spinner } from "../spinner"
import PropTypes from "prop-types"
import { cn } from "@/lib/utils"
import DOMPurify from "isomorphic-dompurify"
import { urlAsset } from "@/helper/utils";

function PopOut({
  isPopupOpen,
  selectedItem,
  handleClaim,
  auth,
  failedAuth,
  handleClose,
}) {
  const { replace } = useRouter()
  const pathname = usePathname()
  const isMobile = useIsMobile()
  const searchParams = useSearchParams()

  const getParamsAldmic = searchParams.get("to") === "aldmic"
  const getParamsId = searchParams.get("id")

  const [openEnvelope, setOpenEnvelope] = useState(false)
  const [title, setTitle] = useState(
    `${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "" : "Tunggu Sebentar"}`
  )
  const [msg, setMsg] = useState(
    `<div><p>${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "Kamu" : "Lo"} sedang diarahkan ke Aldmic - </p><p>${process.env.NEXT_PUBLIC_META_TITLE} <em>Official Rewards Partner</em></p></div>`
  )

  useEffect(() => {
    if (auth) {
      setTitle("YAY!")
      setMsg("Voucher akan ditampilkan, mohon tunggu sebentar lagi")
    }
  }, [auth])

  const titleBrand =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? "<p>Siap Untuk Klaim <em>Rewards</em>?</p>"
      : "<p>Lanjut <em>Claim</em> Hadiah Lo?</p>"

  const subBrand =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? "<p>Beragam <em>rewards</em> menarik bisa kamu dapatkan di sini</p>"
      : "<p>Banyak hadiah-hadiah menarik yang bisa lo klaim di sini</p>"

  const ctaBrand =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? "<p>Cek <em>Rewards</em>-nya</p>"
      : "<p>Cek Rewardsnya</p>"

  /* redirect to aldmic params */
  useEffect(() => {
    if (getParamsAldmic && getParamsId) {
      setOpenEnvelope(true)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [getParamsAldmic, getParamsId])

  useEffect(() => {
    if (failedAuth) {
      setOpenEnvelope(false)
    }
  }, [failedAuth])

  const switchText = (intent) => {
    switch (intent) {
      case "primary":
        return "var(--cta_2)"
      case "secondary":
        return "var(--cta_1)"
      case "tertiary":
        return "var(--cta_1)"
      default:
        return "var(--text_3)"
    }
  }

  return (
    <PopupAction
      isShowing={isPopupOpen}
      zIndex="z-20"
      className={openEnvelope && "!bg-transparent !text-black !p-0"}
      onClickBtnX={() => handleClose(false)}
      isScroll={false}
      // isShowing={isShowing}
      href={pathname}
      showCloseBtn={!openEnvelope}
    >
      {openEnvelope ? (
        <div className={clsx("relative", isMobile ? "w-[338px]" : "w-[400px]")}>
          <div
            className={clsx(
              "relative z-[1] aspect[316/171] h-fit",
              isMobile ? "w-[338px]" : "w-[400px]"
            )}
          >
            <Image
              alt="envelope"
              src={`${urlAsset()}/envelope/envelope-aldmic.png`}
            />
          </div>
          <div
            className={clsx(
              "bg-white absolute top-0 left-2/4 rounded-[10px] h-[230px] z-[-1] text-center p-[16px] capitalize",
              isMobile ? "w-[318px]" : "w-[380px]",
              style.envelope
            )}
          >
            {!auth && <Spinner />}
            <span>{title}</span>
            <Description
              className={clsx(isMobile ? "text-sm" : "text-base")}
              description={msg}
            />
            {auth && <Spinner />}
          </div>
        </div>
      ) : (
        <>
          <div
            className={cn(
              "h-full relative aspect-[304/177]",
              process.env.NEXT_PUBLIC_NAME === "djisamsoe"
                ? "rounded-[8px] overflow-hidden"
                : ""
            )}
          >
            <Image
              alt="banner"
              objectFit={
                process.env.NEXT_PUBLIC_NAME === "djisamsoe"
                  ? "contain"
                  : "cover"
              }
              src={`${urlAsset()}/profile-edit/claim.png`}
            />
          </div>
          <div className="mx-auto my-[20px] w-[304px]">
            <Description
              className="text-xl font-black font-font-family-8 mb-[5px] text-text-2"
              description={titleBrand}
            />
            <Description
              className="text-sm font-medium font-font-family-6 text-text-2"
              description={subBrand}
            />
          </div>
          {failedAuth && (
            <em className="text-[#ed1c24]">Error, Please Try Again!</em>
          )}
          <div className="flex items-center justify-between gap-[10px]">
            <ButtonAction
              className="py-[15px] px-[20px] leading-[20px]"
              intent="primary"
              onClick={() => {
                if (!selectedItem) {
                  setOpenEnvelope(true)
                  setTimeout(() => handleClaim(), 2000)
                } else {
                  replace(selectedItem)
                }
              }}
              noTextFill={true}
            >
              <div
                className="!bg-clip-text relative z-[1]"
                style={{
                  background: switchText("primary"),
                  WebkitTextFillColor: "transparent",
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(ctaBrand),
                }}
              ></div>
            </ButtonAction>
          </div>
        </>
      )}
    </PopupAction>
  )
}

PopOut.propTypes = {
  isPopupOpen: PropTypes.bool,
  selectedItem: PropTypes.string,
  handleClaim: PropTypes.func,
  auth: PropTypes.bool,
}

export default PopOut
