/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import ButtonBack from "../../shared/button-back/button-back";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import { Description } from "../../shared/description/description";
import { useDropzone } from "react-dropzone";
import { useDatalayer } from "@/hooks/use-datalayer";
import { ClientApiList } from "@/lib/api/client-service";
import ButtonAction from "../../shared/button-action/button-action";
import { usePathname, useRouter } from "next/navigation";
import { useWindowSize } from "@/hooks/use-window-size";
import UseQueryParams from "@/hooks/use-query-params";
import { Spinner } from "../../shared/spinner";
import moment from "moment";
import FieldTextarea from "../components/field-textarea";
import SubmissionDropzone from "../components/submission-dropzone";
import { useFieldArray, useForm } from "react-hook-form";
import FieldText from "../components/field-text";
import {
  IconAdd,
  IconErrors,
  IconTrash,
  IconUploadAudio,
} from "../components/submission-icons";
import SubmissionCollapse from "../components/submission-collapse";
import {
  consoleHelper,
  networkFlow,
  statusConts,
  toProxy,
} from "@/helper/utils";
import SubmissionPopup from "./components/submission-popup";
import PreviewFile from "../components/preview-file";
import PreviewAudio from "./components/preview-audio";
import { useIsLogin } from "@/hooks/auth/use-is-login";
import { Image } from "@/components/shared/image/image";
import BackToTop from "./components/back-to-top";
import FieldSelectSearch from "../components/field-select-search";

const initialValues = {
  song_title: "",
  about_song: "",
  band_name: "",
  city: "",
  band_genre: "",
  sub_genre: "",
  band_biography: "",
  links: [{ value: "" }],
};

const maxAudioSize = 10000000; // 10MB
const maxShortChar = 80;
const maxLongChar = 1000;
const audioType = {
  "audio/mpeg": [],
  "audio/wav": [],
};

const AudioSubmission = ({ submission, ...props }) => {
  const brandName = process.env.NEXT_PUBLIC_NAME;
  const QUERYPARAM = "point";
  const { handlePushQuery } = UseQueryParams();
  const captchaRef = useRef();
  const { stage, setStage, data = {} } = props;

  const { width } = useWindowSize();
  const isDesktop = width >= 1024;
  const datalayer = useDatalayer();
  const pathname = usePathname();
  const { replace } = useRouter();
  const { isLogin } = useIsLogin();

  const [audioFiles, setAudioFiles] = useState([]);
  const [coverFiles, setCoverFiles] = useState([]);
  const [coverSelected, setCoverSelected] = useState(null);
  const [logoFiles, setLogoFiles] = useState([]);
  const [pressFiles, setPressFiles] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [errors, setErrors] = useState({
    audio: "",
    cover: "",
    logo: "",
    press: "",
  });
  const [isPopupDelete, setIsPopupDelete] = useState(false);
  const [isPopupConfirm, setIsPopupConfirm] = useState(false);
  const [isPopupShowing, setIsPopupShowing] = useState(false);
  const [listFieldStatus, setListFieldStatus] = useState({});
  const [isBandProfileExist, setIsBandProfileExist] = useState(false);

  const [popupData, setPopupData] = useState({});
  const [preview, setPreview] = useState({
    open: false,
    file: [],
  });
  const [previewAudio, setPreviewAudio] = useState(false);

  const [loading, setLoading] = useState(false);

  const formatAudio = `Format audio MP3 atau WAV dengan ukuran maksimum 10MB`;
  const formatCover = `Pilih atau upload gambar ${brandName === "djisamsoe" ? "kamu" : "lo"} sendiri. Maksimal 5 gambar dengan format .jpg .jpeg .png dengan ukuran 1:1 atau maksimum 5MB`;
  const formatLogo =
    "Format gambar .jpg .jpeg .png. Berukuran 1:1 atau maksimum 5MB";
  const formatPress =
    "Maksimal 10 gambar. Format gambar .jpg .jpeg .png dengan ukuran 1:1 atau maksimum 5MB";

  const bgImg = isDesktop
    ? submission?.desktop_image_bg
    : submission?.mobile_image_bg;

  const { handleSubmit, control, getValues, watch } = useForm({
    mode: "all",
    defaultValues: { ...initialValues },
  });

  const {
    fields: fieldLinks,
    append: appendLinks,
    remove: removeLinks,
  } = useFieldArray({
    name: "links",
    control,
  });

  useEffect(() => {
    if (!isLogin) return;

    fetchCities();
    fetchCoverTemplate();
    fetchFieldStatus();

    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      audioFiles.forEach((file) => URL.revokeObjectURL(file.preview));
      coverFiles.forEach((file) => URL.revokeObjectURL(file.preview));
      logoFiles.forEach((file) => URL.revokeObjectURL(file.preview));
      pressFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [isLogin]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [stage]);

  useEffect(() => {
    // ALWAYS CHECK DISABLED AFTER EDIT FORM
    handleDisabledSubmit();
  }, [watch()]);

  const fetchCoverTemplate = async () => {
    const list = submission?.list_cover?.desktop?.map((file) => toProxy(file));
    const newList = list.map(async (item) => {
      return await fetchImageUrl(item);
    });

    Promise.all(newList)
      .then(async (values) => {
        const readFiles = values.map(
          (file) =>
            new Promise((resolve) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                const base64DataUrl = reader.result;
                const result = Object.assign(file, {
                  preview: base64DataUrl,
                  isRemovable: false,
                });
                resolve(result);
              };
              reader.readAsDataURL(file);
            })
        );

        return await Promise.all(readFiles);
      })
      .then((newVal) => {
        setCoverFiles(newVal);
      });
  };

  const fetchImageUrl = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], "image.png", {
      type: "image/png",
    });
  };

  const fetchCities = async () => {
    try {
      const { data } = await ClientApiList.getMasterCity();
      setCityList(data?.data?.list_city);
    } catch (err) {
      console.log("[Error]", err);
    }
  };

  const fetchFieldStatus = () => {
    const fieldBandProfile = [
      "band_name",
      "city",
      "band_genre",
      "sub_genre",
      "band_logo",
      "official_press_photo",
      "short_biography",
    ];

    let newList = {};
    submission?.list_field_status?.forEach((list) => {
      newList[list.string_id] = list?.status;
    });
    setListFieldStatus(newList);

    const isBandProfile = fieldBandProfile.map((field) => newList?.[field]);
    setIsBandProfileExist(isBandProfile.some((fill) => fill));
  };

  const handleDisabledSubmit = () => {
    const isFilesEmpty =
      audioFiles.length < 1 ||
      (listFieldStatus?.band_logo && logoFiles.length < 1) ||
      (listFieldStatus?.official_press_photo && pressFiles.length < 1);

    const listText = [
      "song_title",
      "about_song",
      "band_name",
      "city",
      "band_genre",
      "sub_genre",
    ];

    const empty = listText.map(
      (field) => listFieldStatus?.[field] && isFieldTextEmpty(field)
    );
    return (
      isFilesEmpty ||
      empty.some((field) => field) ||
      (listFieldStatus?.cover_image && coverSelected === null) ||
      !submission?.chances
    );
  };

  const isFieldTextEmpty = (field) => {
    switch (field) {
      case "song_title":
        return !getValues(field) || getValues(field).length > maxShortChar;
      case "about_song":
        return !getValues(field) || getValues(field).length > maxLongChar;
      default:
        return !getValues(field);
    }
  };

  const handlePreviewButton = () => {
    if (
      audioFiles.length < 1 ||
      (listFieldStatus?.cover_image && coverSelected === null)
    ) {
      if (audioFiles.length < 1)
        setErrors((state) => ({ ...state, audio: "Audio harus diupload" }));
      if (coverSelected === null)
        setErrors((state) => ({ ...state, cover: "Pilih salah satu cover" }));
    } else {
      setPreviewAudio(true);
    }
  };

  const { getRootProps: getRootAudio, getInputProps: getInputAudio } =
    useDropzone({
      maxFiles: 1,
      maxSize: maxAudioSize,
      accept: audioType,
      disabled: !!audioFiles.length,
      onDrop: async (acceptedFiles, fileRejections) => {
        const formattedFiles = [];
        for (const file of acceptedFiles) {
          const createObjectURL =
            window.webkitURL.createObjectURL || window.URL.createObjectURL;
          const preview = createObjectURL(
            new Blob([file], { type: file.type })
          );

          const result = Object.assign(file, {
            preview: preview,
            filename: file.name.slice(0, file.name.length - 4), // 4 is from .mp3
            filesize: convertBytesToMB(file.size),
            duration: await getAudioDuration(preview),
          });

          formattedFiles.push(result);
        }

        setAudioFiles(audioFiles.concat(formattedFiles));

        if (fileRejections.length === 0) {
          setErrors((state) => ({ ...state, audio: "" }));
        } else {
          fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
              if (err.code === "file-too-large") {
                setErrors((state) => ({
                  ...state,
                  audio: `Ukuran file terlalu besar`,
                }));
              } else if (err.code === "file-invalid-type") {
                setErrors((state) => ({
                  ...state,
                  audio: `Format file tidak sesuai`,
                }));
              } else if (err.code === "too-many-files") {
                setErrors((state) => ({
                  ...state,
                  audio: `File tidak boleh lebih dari 1`,
                }));
              }
            });
          });
        }
      },
    });

  const getAudioDuration = (preview) => {
    return new Promise((resolve) => {
      const audio = document.createElement("AUDIO");
      audio.src = preview;

      audio.addEventListener("loadedmetadata", () => {
        const utc = moment.utc(parseInt(audio.duration) * 1000).format("mm:ss");
        resolve(utc);
      });

      audio.addEventListener("error", () => {
        resolve("Error loading audio");
      });
    }).then((duration) => duration);
  };

  const convertBytesToMB = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2);
  };

  const convertFileToBlob = (file) => {
    return Object.assign(file, {
      preview: URL.createObjectURL(new Blob([file], { type: file.type })),
    });
  };

  const handleClosePopup = () => {
    setIsPopupConfirm(false);
    setIsPopupDelete(false);
    setIsPopupShowing(false);
  };

  const popupSubmit = () => {
    setIsPopupConfirm(true);
    setIsPopupShowing(true);
  };

  const onSubmit = async (values) => {
    setLoading(true);
    captchaRef.current.reset();
    const token = await captchaRef.current.executeAsync();
    if (!token) {
      replace(pathname);
      return;
    }

    try {
      let formData = new FormData();
      formData.append("engagement_uuid", data?.engagement_uuid);
      formData.append("type", data?.type);
      formData.append("g-recaptcha-response", token);
      formData.append("audio", audioFiles[0]);
      coverSelected &&
        formData.append("cover", convertFileToBlob(coverFiles[coverSelected]));
      logoFiles.length &&
        formData.append("logo", convertFileToBlob(logoFiles[0]));
      pressFiles.forEach((element) => {
        formData.append("official", convertFileToBlob(element));
      });
      formData.append("title", values?.song_title);
      formData.append("caption", values?.about_song);
      formData.append("band_name", values?.band_name);
      formData.append("city", values?.city);
      formData.append("genre", values?.band_genre);
      formData.append("subgenre", values?.sub_genre);
      formData.append("biography", values?.band_biography);
      values?.links.forEach((link) => {
        formData.append("link", link?.value);
      });

      const { data: submitData } =
        await ClientApiList.postAnswerAudio(formData);

      if (submitData) {
        datalayer.push({
          event: "general_event",
          event_name: "click_kirim_submission_file",
          feature: "simple engagement",
          engagement_type: "submission",
          engagement_name: `${data?.type?.toLowerCase()}`,
          campaign_name: data?.campaign_name?.toLowerCase(), // e.g all
          event_label: `submission ${data?.type?.toLowerCase()} - ${data?.subtitle_introduction?.toLowerCase()}`,
          level: 1,
          content_id: data?.engagement_uuid,
        });

        const { data: dataStatus } = await ClientApiList.getGameConfirmation({
          engagement_uuid: data?.engagement_uuid,
          type: data?.type,
          status: "completed",
        });

        if (dataStatus) {
          setPopupData({ ...dataStatus.data.data });
          setTimeout(() => {
            setIsPopupShowing(true);
          }, 600);

          datalayer.push({
            event: "general_event",
            event_name: `page_reached_finish_on_${data?.type?.toLowerCase()}`,
            feature: "simple engagement",
            engagement_type: "submission",
            engagement_name: `${data?.type?.toLowerCase()}`,
            campaign_name: data?.campaign_name?.toLowerCase(), // e.g all
            event_label: `submission ${data?.type?.toLowerCase()} - ${data?.subtitle_introduction?.toLowerCase()}`,
            character_result:
              dataStatus?.data?.data?.subtitle_confirmation?.toLowerCase(),
            level: 1,
            content_id: data?.engagement_uuid,
          });
          datalayer.push({
            event: "general_event",
            event_name: `page_reached_success_submit_${data?.type?.toLowerCase()}`,
            feature: "simple engagement",
            engagement_type: "submission",
            engagement_name: `${data?.type?.toLowerCase()}`,
            campaign_name: data?.campaign_name?.toLowerCase(), // e.g all
            event_label: `submission ${data?.type?.toLowerCase()} - ${data?.subtitle_introduction?.toLowerCase()}`,
            character_result:
              dataStatus?.data?.data?.subtitle_confirmation?.toLowerCase(),
            level: 1,
            content_id: data?.engagement_uuid,
          });

          handlePushQuery(false, [QUERYPARAM, true]);
          return;
        }
      }
    } catch (error) {
      setLoading(false);
      consoleHelper(statusConts.error, error, networkFlow.received);
    }
    setLoading(false);
  };

  return (
    <>
      {stage != 1 && (
        <div
          className={clsx(
            "relative w-full h-full min-h-[715px] text-text-2",
            isDesktop && "mb-[112px]"
          )}
        >
          <div
            className={clsx(
              "absolute z-1 overflow-hidden",
              isDesktop
                ? "aspect[1410/520] left-[15px] right-[15px] rounded-[15px]"
                : "top-[-20px] left-0 right-0 h-[715px] w-[100%] rounded-b-[10px] "
            )}
          >
            {bgImg && <Image data-testid="bg-img" src={bgImg} alt="cover" />}
          </div>
          <ButtonBack
            className="top-[-10px] left-[6px]"
            onClick={() => setStage(1)}
          />

          <div
            className={clsx(
              "relative w-full max-w-[461px] mx-auto",
              isDesktop ? "pt-[285px]" : "pt-[216px] px-[10px]"
            )}
          >
            <form onSubmit={handleSubmit(popupSubmit)}>
              <div
                className="bg-bg-3 p-[15px] rounded-[10px] overflow-hidden"
                encType="multipart/form-data"
                method="post"
              >
                <h2 className="text-xl font-bold font-font-family-7">
                  {submission?.audio_question}
                </h2>
                <Description
                  className={clsx(
                    "text-sm font-medium font-font-family-6 my-[10px]",
                    brandName === "djisamsoe"
                      ? "text-text-2"
                      : " text-[#413F3F]"
                  )}
                  description={submission?.audio_short_desc}
                />

                {/* UPLOAD AUDIO */}
                <div className="p-[10px] pb-[15px] bg-bg-1 rounded-[10px] border-[1px] border-cta-4 overflow-hidden">
                  <Label>Upload Audio</Label>
                  <div
                    className={clsx(
                      "flex flex-wrap items-center",
                      isDesktop ? "gap-[10px]" : "gap-[5px]"
                    )}
                  >
                    <div
                      {...getRootAudio({
                        className: clsx(
                          "p-[11px] dropzone w-full rounded-[10px] border-[1px] overflow-hidden flex justify-between items-center",
                          !audioFiles.length
                            ? "border-dashed border-cta-1 cursor-pointer"
                            : "bg-bg-3 border-transparent drop-shadow-[1px_2px_5px_#0000000D]"
                        ),
                      })}
                    >
                      <input
                        data-testid="drop-input"
                        {...getInputAudio()}
                        onClick={() =>
                          setErrors((state) => ({ ...state, audio: "" }))
                        }
                      />
                      <div className="flex flex-col gap-1">
                        <span
                          className={clsx(
                            !audioFiles.length && "text-text-1",
                            "w-full font-bold font-font-family-7 text-sm tracking-[0.275px] line-clamp-1"
                          )}
                        >
                          {audioFiles.length > 0
                            ? audioFiles[0]?.filename
                            : "Upload Audio"}
                        </span>
                        <span
                          className={clsx(
                            !audioFiles.length ? "text-text-1" : "text-text-3",
                            "text-[10px] font-medium"
                          )}
                        >
                          {audioFiles.length > 0
                            ? `${audioFiles[0]?.filesize}MB | ${audioFiles[0]?.duration}`
                            : "MP3 | Max. 10MB"}
                        </span>
                      </div>
                      {audioFiles.length > 0 ? (
                        <button
                          type="button"
                          onClick={() => {
                            setIsPopupDelete(true);
                            setIsPopupConfirm(true);
                            setIsPopupShowing(true);
                          }}
                        >
                          <IconTrash />
                        </button>
                      ) : (
                        <IconUploadAudio />
                      )}
                    </div>
                  </div>
                  <DescriptionErrors
                    description={formatAudio}
                    errors={errors?.audio}
                  />
                </div>

                {/* SONG COVER */}
                {listFieldStatus?.cover_image && (
                  <div className="p-[10px] pb-[15px] bg-bg-1 rounded-[10px] border-[1px] border-cta-4 overflow-hidden my-[10px]">
                    <Label>
                      {submission?.cover_image_label ?? "Pilih Cover Lagu"}
                    </Label>
                    {/* TODO: BUAT LOADING */}
                    <div className="flex items-center gap-[5px] overflow-y-auto pr-[5px]">
                      <SubmissionDropzone
                        maxSubmission={
                          5 + submission?.list_cover?.desktop.length
                        }
                        submissionFiles={coverFiles}
                        setSubmissionFiles={setCoverFiles}
                        submissionErrors={errors?.cover}
                        setSubmissionErrors={(err) =>
                          setErrors((state) => ({ ...state, cover: err }))
                        }
                        setPreview={setPreview}
                        selected={coverSelected}
                        setSelected={setCoverSelected}
                      />
                    </div>
                    <DescriptionErrors
                      description={formatCover}
                      errors={errors?.cover}
                    />
                  </div>
                )}

                {/* JUDUL LAGU */}
                {listFieldStatus?.song_title && (
                  <div className="p-[10px] pb-[15px] bg-bg-1 rounded-[10px] border-[1px] border-cta-4 overflow-hidden my-[10px]">
                    <Label>
                      {submission?.song_title_label ?? "Judul Lagu"}
                    </Label>
                    <FieldTextarea
                      name="song_title"
                      control={control}
                      placeholder={`Beri judul buat lagu ${brandName === "djisamsoe" ? "mu" : "lo"} di sini...`}
                      maxChar={maxShortChar}
                    />
                  </div>
                )}

                {/* TENTANG LAGU */}
                {listFieldStatus?.about_song && (
                  <div className="p-[10px] pb-[15px] bg-bg-1 rounded-[10px] border-[1px] border-cta-4 overflow-hidden my-[10px]">
                    <Label>
                      {submission?.about_song_label ?? "Tentang Lagu Ini"}
                    </Label>
                    <FieldTextarea
                      name="about_song"
                      control={control}
                      placeholder={`Tulis cerita tentang lagu ${brandName === "djisamsoe" ? "mu" : "lo"} di sini...`}
                      rows={6}
                      maxChar={maxLongChar}
                    />
                  </div>
                )}

                {/* PROFIL BAND */}
                {isBandProfileExist && (
                  <SubmissionCollapse
                    name="band_profile"
                    index={0}
                    title={submission?.band_profile_label ?? "Profil Band"}
                  >
                    {listFieldStatus?.band_name && (
                      <div>
                        <Label tier={2} isRequired>
                          Nama Band
                        </Label>
                        <FieldText
                          name="band_name"
                          control={control}
                          placeholder="Tulis Nama Band"
                        />
                      </div>
                    )}
                    {listFieldStatus?.city && (
                      <div>
                        <Label tier={2} isRequired>
                          Kota Asal
                        </Label>
                        <FieldSelectSearch
                          name="city"
                          control={control}
                          options={cityList}
                          placeholder="Pilih Kota"
                        />
                      </div>
                    )}
                    {listFieldStatus?.band_genre && (
                      <div>
                        <Label tier={2} isRequired>
                          Genre Lagu
                        </Label>
                        <FieldText
                          name="band_genre"
                          control={control}
                          placeholder="Isi Genre"
                        />
                      </div>
                    )}
                    {listFieldStatus?.sub_genre && (
                      <div>
                        <Label tier={2} isRequired>
                          Sub-Genre
                        </Label>
                        <FieldText
                          name="sub_genre"
                          control={control}
                          placeholder="Isi Sub-genre"
                        />
                      </div>
                    )}
                    {listFieldStatus?.band_logo && (
                      <div>
                        <Label tier={2} isRequired>
                          Logo Band
                        </Label>
                        <div className="p-[10px] bg-bg-3 drop-shadow-[1px_2px_5px_#0000000D] rounded-[5px]">
                          <div className="flex items-center gap-[5px] overflow-y-auto">
                            <SubmissionDropzone
                              submissionFiles={logoFiles}
                              setSubmissionFiles={setLogoFiles}
                              setSubmissionErrors={(err) =>
                                setErrors((state) => ({ ...state, logo: err }))
                              }
                              setPreview={setPreview}
                            />
                          </div>
                          <DescriptionErrors
                            description={formatLogo}
                            errors={errors?.logo}
                          />
                        </div>
                      </div>
                    )}
                    {listFieldStatus?.official_press_photo && (
                      <div>
                        <Label tier={2} isRequired>
                          Official Press Photo
                        </Label>
                        <div className="p-[10px] bg-bg-3 drop-shadow-[1px_2px_5px_#0000000D] rounded-[5px]">
                          <div className="flex items-center gap-[5px] overflow-y-auto">
                            <SubmissionDropzone
                              maxSubmission={10}
                              submissionFiles={pressFiles}
                              setSubmissionFiles={setPressFiles}
                              setSubmissionErrors={(err) =>
                                setErrors((state) => ({ ...state, press: err }))
                              }
                              setPreview={setPreview}
                            />
                          </div>
                          <DescriptionErrors
                            description={formatPress}
                            errors={errors?.press}
                          />
                        </div>
                      </div>
                    )}
                    {listFieldStatus?.short_biography && (
                      <div>
                        <Label tier={2}>Biografi Band</Label>
                        <FieldTextarea
                          name="band_biography"
                          control={control}
                          placeholder={`Tulis biografi singkat band ${brandName === "djisamsoe" ? "mu" : "lo"} di sini...`}
                          rows={6}
                          maxChar={maxLongChar}
                        />
                      </div>
                    )}
                  </SubmissionCollapse>
                )}

                {/* ADDITIONAL INFORMATION */}
                {listFieldStatus?.additional_information && (
                  <SubmissionCollapse
                    name="additional_information"
                    index={0}
                    title={
                      submission?.additional_information_label ??
                      "Link Informasi Tambahan"
                    }
                  >
                    {fieldLinks?.map((field, index) => (
                      <div key={field?.id}>
                        <div className="flex justify-between">
                          <Label tier={2}>Link {index + 1}</Label>
                          {fieldLinks?.length > 1 && (
                            <button
                              type="button"
                              className="text-[12px] font-medium font-font-family-6 text-text-1 underline"
                              onClick={() => removeLinks(index)}
                            >
                              Hapus Link
                            </button>
                          )}
                        </div>
                        <FieldText
                          name={`links.${index}.value`}
                          control={control}
                          placeholder="Contoh: https://www.youtube.com/@BUILDERBUCHMUSIK"
                        />
                      </div>
                    ))}
                    {fieldLinks?.length < 5 && (
                      <ButtonAction
                        type="button"
                        data-testid="btn-add-link"
                        intent="primary"
                        className="py-[6px] px-[20px] w-fit !text-[12px]"
                        onClick={() => appendLinks({ value: "" })}
                        icon={
                          <IconAdd
                            width={16}
                            height={16}
                            className={
                              brandName === "djisamsoe" && "stroke-[#221F20]"
                            }
                          />
                        }
                      >
                        Tambah Link
                      </ButtonAction>
                    )}
                  </SubmissionCollapse>
                )}

                {/* BUTTONS */}
                <div className="flex gap-[10px]">
                  <ButtonAction
                    type="button"
                    data-testid="submit-btn"
                    className="py-[15px] px-[20px]"
                    onClick={() => handlePreviewButton()}
                    intent="secondary"
                  >
                    Preview
                  </ButtonAction>
                  {loading ? (
                    <div className="w-full flex justify-center items-center">
                      <Spinner />
                    </div>
                  ) : (
                    <ButtonAction
                      type="submit"
                      data-testid="submit-btn"
                      className="py-[15px] px-[20px]"
                      intent={
                        handleDisabledSubmit() ? "primary_disable" : "primary"
                      }
                      disabled={handleDisabledSubmit()}
                    >
                      Kirim
                    </ButtonAction>
                  )}
                </div>

                <ReCAPTCHA
                  ref={captchaRef}
                  size="invisible"
                  sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA}
                />
              </div>
            </form>
          </div>

          {/* PREVIEW IMAGE */}
          {preview?.open && (
            <PreviewFile
              submission_type="image"
              file_src={preview?.file?.preview}
              handleClose={() => setPreview({ open: false, file: [] })}
            />
          )}

          {/* PREVIEW AUDIO */}
          <PreviewAudio
            open={previewAudio}
            close={() => setPreviewAudio(false)}
            data={{
              listFieldStatus: listFieldStatus,
              audio: audioFiles,
              cover: coverFiles[coverSelected]?.preview,
              band_logo: logoFiles,
              official_press_photo: pressFiles,
              ...getValues(),
            }}
          />

          {/* POPUP */}
          <SubmissionPopup
            open={isPopupShowing}
            close={() => handleClosePopup()}
            isPopupConfirm={isPopupConfirm}
            isPopupDelete={isPopupDelete}
            handleSubmit={() => onSubmit(getValues())}
            deleteAudio={() => setAudioFiles([])}
            setStage={setStage}
            popupData={popupData}
          />

          {/* BUTTON BACK TO TOP */}
          <BackToTop />
        </div>
      )}
    </>
  );
};

export default AudioSubmission;
AudioSubmission.propTypes = {
  submission: PropTypes.object,
  stage: PropTypes.number,
  setStage: PropTypes.func,
  image_url: PropTypes.string,
  maxShortChar: PropTypes.number,
  maxLongChar: PropTypes.number,
  amount_of_submission: PropTypes.number,
  max_size: PropTypes.number,
  type_file: PropTypes.string,
  data: PropTypes.object,
};

export const DescriptionErrors = ({ description, errors }) => {
  const brandName = process.env.NEXT_PUBLIC_NAME;
  const { width } = useWindowSize();
  const isDesktop = width >= 1024;

  return (
    <>
      <p
        className={clsx(
          "font-normal font-font-family-5 mt-[10px]",
          isDesktop ? "text-sm" : "text-xs",
          brandName === "djisamsoe" ? "text-text-2" : "text-[#413F3F]"
        )}
      >
        {description}
      </p>
      {errors && (
        <div className="w-full flex items-center gap-[5px] rounded-[5px] text-xs text-text-1 bg-cta-3 mt-[10px] p-[10px] tracking-[0.25px]">
          <IconErrors />
          <span>{errors}</span>
        </div>
      )}
    </>
  );
};
DescriptionErrors.propTypes = {
  description: PropTypes.string,
  errors: PropTypes.string,
};

const Label = ({ tier = 1, isRequired = false, children }) => (
  <span
    className={clsx(
      "block text-sm capitalize",
      tier === 1
        ? "font-bold font-font-family-7 mb-[10px]"
        : "font-medium font-font-family-6 mb-[5px]"
    )}
  >
    {children}
    {isRequired && <span className="text-text-1">*</span>}
  </span>
);
Label.propTypes = {
  tier: PropTypes.number,
  isRequired: PropTypes.bool,
  children: PropTypes.any,
};
