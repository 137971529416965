"use client"
import React from "react"
import ProgressBar from "../../shared/progress-bar/progress-bar"
import { Image } from "@/components/shared/image/image"
import PropTypes from "prop-types"
import { urlAsset } from "@/helper/utils";
export default function TierDescription({
  expCount,
  nextExpCount,
  expCountToNextTier,
  nextTier,
}) {
  return (
    <>
      <div className="flex justify-between items-center mb-[5px] text-text-2 relative z-[1]">
        <div
          data-testid="tier-next-description"
          className="text-[10px] lg:text-[12px] leading-[16px] font-medium font-font-family-6 tracking-[0.2px]"
        >
          <span className="font-bold font-font-family-7">
            {expCountToNextTier} EXP
          </span>{" "}
          lagi buat naik ke level {nextTier}
        </div>
        <div className="flex justify-between items-center gap-[5px] text-[12px] lg:text-[14px] font-bold font-font-family-7 leading-[13px]">
          <div data-testid="tier-description-exp-count">
            <span className="text-text-1">{expCount}</span>/{nextExpCount}
          </div>
          <div className="w-[16px] h-[16px] bg-bg-1 relative">
            <Image
              alt="cover"
              objectFit="contain"
              src={`${urlAsset()}/mission-page/cup.svg`}
              className="w-[16px] h-[16px]"
            />
          </div>
        </div>
      </div>
      <ProgressBar current={expCount} total={nextExpCount} />
    </>
  )
}

TierDescription.propTypes = {
  expCount: PropTypes.number,
  nextExpCount: PropTypes.number,
  expCountToNextTier: PropTypes.number,
  nextTier: PropTypes.number,
}
