/* eslint-disable react-hooks/exhaustive-deps */
import { cn } from "@/lib/utils";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Chevron } from "@/components/sections/bantuan";
import { Description } from "@/components/shared/description/description";
import Style from "@/components/sections/brand-popup.module.css";
import { Controller } from "react-hook-form";
import { debounce } from "lodash";

const iconVariants = {
  open: { rotate: 0 },
  closed: { rotate: -180 },
};

// TODO: BELUM DINAMIS -> target key yang mau digunakan untuk value
const FieldSelectSearch = ({ name, control, options = [], placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [lastValue, setLastValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(options);
  const dropdown = useRef(null);

  useEffect(() => {
    if (!isOpen) return;
    function handleClick(event) {
      if (dropdown.current && !dropdown.current.contains(event.target)) {
        setSearchTerm(lastValue.length ? lastValue : "");
        setFilteredData(options);
        setIsOpen(false);
      }
    }
    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, [isOpen]);

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term.toLowerCase());
  };

  const debouncedSearch = debounce((term) => {
    const results = options.filter((item) =>
      item.name.toLowerCase().includes(term)
    );
    setFilteredData(results);
  }, 300);

  const handleClickFiltered = (value) => {
    setLastValue(value);
    setSearchTerm(value);
    setFilteredData(options);
    setIsOpen(false);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <div ref={dropdown} className="relative w-full mx-auto">
          <button
            type="button"
            onClick={() =>
              setIsOpen((state) => (searchTerm.length ? true : !state))
            }
            className={clsx(
              "relative w-full lg:min-w-[120px] p-[10px] text-left text-sm flex justify-between items-center border rounded-[10px] capitalize bg-bg-3",
              isOpen ? "border-cta-1" : "border-cta-4"
            )}
          >
            <input
              type="text"
              className="w-full bg-transparent disabled:bg-cta-4 text-[14px] text-text-2 leading-[20px] disabled:text-text-3 placeholder:text-text-3 placeholder:italic outline-none"
              onChange={handleSearch}
              value={searchTerm}
              placeholder={placeholder}
            />
            <AnimatePresence>
              <motion.span
                key="icon"
                initial={false}
                animate={isOpen ? "open" : "closed"}
                variants={iconVariants}
                transition={{ duration: 0.2 }}
              >
                <Chevron className={"fill-icon-2"} />
              </motion.span>
            </AnimatePresence>
          </button>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                key={"options"}
                initial={{
                  opacity: 0,
                  y: -10,
                }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
                className={cn(
                  "w-[100%] max-h-[130px] absolute z-[5] top-[42px] border border-cta-1 rounded-[10px] bg-bg-3 overflow-hidden"
                )}
              >
                <div
                  className={cn(
                    "max-h-[130px] overflow-y-scroll flex flex-col gap-[10px] p-[10px]",
                    Style.container
                  )}
                >
                  {filteredData.length > 0 ? (
                    filteredData?.map((item) => (
                      <button
                        key={item?.id}
                        type="button"
                        onClick={() => {
                          onChange(item?.name);
                          handleClickFiltered(item?.name);
                        }}
                      >
                        <Description
                          className="text-ellipsis overflow-hidden lg:max-w-[170px] text-text-2 text-left text-sm capitalize"
                          description={item?.name}
                        />
                      </button>
                    ))
                  ) : (
                    <p className="text-text-3 text-sm">No results found</p>
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    />
  );
};

FieldSelectSearch.propTypes = {
  name: PropTypes.string,
  control: PropTypes.func,
  options: PropTypes.object,
  placeholder: PropTypes.string,
};

export default FieldSelectSearch;
