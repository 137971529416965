"use client"
import { Image } from "@/components/shared/image/image"
import { urlAsset } from "@/helper/utils";
import React from "react"

export default function LeaderboardUser({ user, position, userPos }) {
  const switchBgDefault = () => {
    switch (process.env.NEXT_PUBLIC_NAME) {
      case "amild":
        return "[background:linear-gradient(90deg,#f5f5f5_0%,#eee_29.17%,#f5f5f5_81%)]"
      case "magnum":
        return "[background:linear-gradient(90deg,#191919_0%,#404040_31.3%,#191919_78.67%,#404040_115.08%)]"
      case "djisamsoe":
        return "[background:linear-gradient(90deg,#110C02_0%,#000000_29.17%,#110C02_81.01%)]"
      case "marlboro":
        return "[background:linear-gradient(90deg,#f5f5f5_0%,#eee_29.17%,#f5f5f5_81%)]"
      default:
        return "[background:linear-gradient(90deg,#FDF7DE_0%,#E2DDCA_29.17%,#FDF7DE_81.01%)]"
    }
  }

  // const switchBgUser = () => {
  //   switch (process.env.NEXT_PUBLIC_NAME) {
  //     case "amild":
  //       return '[background:linear-gradient(90deg,#FFEBEB,#FBD2D3_32.69%,#FFEBEB)]';
  //     case 'magnum':
  //       return '[background:linear-gradient(98.87deg,#A47E24_0%,#6B5217_100%)]';
  //   }
  // };
  // const switchBorderUser = () => {
  //   switch (process.env.NEXT_PUBLIC_NAME) {
  //     case "amild":
  //       return '[background:linear-gradient(90deg,rgba(237,28,36,0.15)_0%,#ED1C24_49.29%,rgba(237,28,36,0.15)_100%)]';
  //     case 'magnum':
  //       return '[background:linear-gradient(98.87deg,#E8B847_0%,#956F15_27.6%,#E8B847_54.17%,#B58C29_100%)]';
  //   }
  // };
  // console.log(userPos, position, 'pos')
  return (
    // <div className={`relative p-[9px] border-[4px] rounded-[10px] lg:rounded-[15px] lg:pl-[19px] lg:pr-[25px] lg:py-[14px] flex items-center text-text-2 ${position === 5 ? '[background:linear-gradient(90deg,#FFEBEB,#FBD2D3_32.69%,#FFEBEB)] from-[#FFEBEB] via-[#FBD2D3] via-[32.69%] to-[#FFEBEB] [border-image:linear-gradient(90deg,rgba(237,28,36,0.15)_0%,#ED1C24_49.29%,rgba(237,28,36,0.15)_100%)_30]' : 'bg-[crimson] border-transparent'}`}>
    <div
      // className={`rounded-[10px] lg:rounded-[15px] p-[1px] ${position !== userPos ? switchBorderUser() : switchBgDefault()}`}
      className={`rounded-[10px] lg:rounded-[15px] p-[1px] relative overflow-hidden ${position === userPos ? "" : switchBgDefault()}`}
    >
      {position === userPos ? (
        <Image
          className={"absolute inset-0 z-[-1]"}
          alt="rank"
          src={`${urlAsset()}/user-profile/rank-desktop.png`}
        />
      ) : null}
      <div
        // className={`relative p-[8px] px-[9px] rounded-[10px] lg:rounded-[15px] lg:pl-[18px] lg:pr-[24px] lg:py-[13px] flex items-center text-text-2 ${position !== userPos ? switchBgUser() : switchBgDefault()}`}
        className={`relative p-[8px] px-[9px] rounded-[10px] lg:rounded-[15px] lg:pl-[18px] lg:pr-[24px] lg:py-[13px] flex items-center text-text-2 ${position === userPos ? "" : switchBgDefault()}`}
      >
        <div
          data-testid="leaderboard-user-position"
          className="min-w-[28px] lg:min-w-[40px] min-h-[28px] lg:min-h-[40px] bg-bg-3 flex items-center justify-center rounded-[10px] order-1 text-[12px] lg:text-[20px] lg:text-[20px] font-bold font-font-family-7 text-text-2 leading-[16px] lg:leading-[27px] lg:p-0 mr-[10px] lg:mr-[20px] border-b-[1px] border-cta-1"
        >
          {position}
        </div>
        <div
          data-testid="leaderboard-user-name"
          className="grow-[1] text-[12px] lg:text-[16px] leading-[16px] lg:leading-[22px] order-3 font-medium font-font-family-6 min-w-[114px] text-text-2"
        >
          {user[1]}
        </div>
        <div className="min-w-[103.62px] text-[14px] lg:text-[16px] leading-[19px] lg:leading-[22px] font-bold font-font-family-7 order-4 text-text-2 flex jutify start items-center gap-[2px]">
          <div className="w-[16px] h-[16px] relative">
            <Image
              className="absolute inset-0"
              alt={user[1]}
              data-testid="leaderboard-user-exp-icon"
              src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/reward-profile.svg`}
            />
          </div>
          <span data-testid="leaderboard-user-exp">{user[2]} Poin</span>
        </div>
        <div className=" min-h-[24px] lg:h-[32px] order-2 min-w-[24px] lg:min-w-[32px] mr-[5px] lg:mr-[15px] rounded-[50%] flex items-center gap-[2px] justify-center">
          <div className="w-[30px] lg:w-[38px] h-[30px] lg:h-[38px] relative flex items-center justify-center">
            <Image
              className="absolute top-[2px] inset-0 object-center"
              src={`${urlAsset()}/user-profile/profile-sm.svg`}
              alt="avatar"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
