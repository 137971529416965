"use client"
import React, { useState, useEffect, useContext, useRef } from "react"
import clsx from "clsx"
import { Image } from "@/components/shared/image/image"
import { Description } from "../shared/description/description"
import { cva } from "class-variance-authority"
import Link from "@/components/shared/link/link"
import ButtonBack from "../shared/button-back/button-back"
import PopupGame from "../shared/popup-game/popup-game"
import { useDatalayer } from "@/hooks/use-datalayer"
import { useInView } from "react-intersection-observer"
import PropTypes from "prop-types"
import { ClientApiList } from "@/lib/api/client-service"
import { useWindowSize } from "@/hooks/use-window-size"
import { PrevPath } from "@/hooks/use-prev-path-ctx"
import { SuccessIcon } from "../sections/article-details"
import ButtonAction from "../shared/button-action/button-action"
import { useForm } from "react-hook-form"
import CheckBox from "../edit-profile/checkbox"
import DOMPurify from "isomorphic-dompurify"
import { Spinner } from "../shared/spinner"

export function LandingWordle(props) {
  const {
    stage,
    setStage,
    wordle,
    pause,
    setPause,
    finished,
    isTnc,
    isTncChecked,
    tnc,
    setIsTncChecked,
  } = props

  const [openHowTo, setOpenHowTo] = useState(false)
  const [openRule, setOpenRule] = useState(false)
  const [loading, setLoading] = useState(false)
  const isSampoernaKretek = process.env.NEXT_PUBLIC_NAME === "sampoernakretek"

  const linkRef = useRef(null)
  const init = useRef(false)

  const { width } = useWindowSize()
  const isDesktop = width >= 1024
  const datalayer = useDatalayer()
  const { prevPath } = useContext(PrevPath)

  const [landingRef, inView] = useInView({
    triggerOnce: true,
  })

  const datalayerView = async () => {
    // const { data: dataProfile } = await ClientApiList.getProfileData()
    datalayer.push({
      event: "general_event",
      event_name: "page_reached_on_simple_engagement",
      feature: "simple engagement",
      page_type: "simple engagement",
      campaign_name: wordle?.campaign_name.toLowerCase(), // e.g all
      event_label: `wordle - ${wordle?.subtitle_introduction.toLowerCase()}`,
      // userld: dataProfile?.data.data.dataProfile,
      content_id: wordle?.engagement_uuid,
    })
  }

  useEffect(() => {
    if (inView) {
      datalayerView()
    }
    // eslint-disable-next-line
  }, [inView])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [stage])

  const { register, watch, setValue } = useForm({
    isAgree: false,
  })

  useEffect(() => {
    if (isTncChecked && !init.current) {
      setValue("isAgree", isTncChecked)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isTncChecked])

  useEffect(() => {
    if (
      linkRef.current &&
      tnc &&
      tnc.includes("target=_blank") &&
      linkRef.current.querySelector("a") &&
      !String(linkRef.current?.querySelector("a")).includes("target=_blank")
    )
      linkRef.current?.querySelector("a")?.setAttribute("target", "_blank")
      linkRef.current?.querySelector("a")?.setAttribute("rel", "noopener")
  })

  const wordBox = cva(
    "font-bold font-font-family-7 border-[1px] border-solid flex items-center justify-center",
    {
      variants: {
        color: {
          red: [
            "bg-cta-1",
            `${isSampoernaKretek ? "text-text-2" : "text-bg-1"}`,
            "border-cta-1",
          ],
          black: ["bg-bg-2", "text-text-4", "border-bg-2"],
          white: ["bg-bg-1", "text-text-1", "border-cta-1"],
        },
        size: {
          small: [
            `${isDesktop ? "w-[26px]" : "w-[20px]"}`,
            `${isDesktop ? "h-[26px]" : "h-[20px]"}`,
            "rounded-[4px]",
            "shadow-[0px_-1.786px_0_0_rgba(0,0,0,0.15)_inset,_0_1.786px_0_0_rgba(255,255,255,0.20)_inset]",
            `${isDesktop ? "text-[11px]" : "text-[9px]"}`,
          ],
          medium: [
            `${isDesktop ? "w-[54px]" : "w-[46px]"}`,
            `${isDesktop ? "h-[54px]" : "h-[46px]"}`,
            "rounded-[8px]",
            "shadow-[0_4px_5px_rgba(0,0,0,0.2),_inset_0_4px_0_rgba(255,255,255,0.2),_inset_0_-4px_0_rgba(0,0,0,0.25)]",
            `${isDesktop ? "text-[23px]" : "text-[20px]"}`,
          ],
        },
      },
      compoundVariants: [{ color: "red", size: "medium" }],
      defaultVariants: {
        color: "red",
        size: "medium",
      },
    }
  )

  const WordleBox = ({ word, color, size }) => {
    const bgWhite =
      process.env.NEXT_PUBLIC_NAME === "magnum" ? "var(--cta_3)" : "#fff"

    const bgBlack =
      process.env.NEXT_PUBLIC_NAME === "magnum"
        ? "#ED1C24"
        : "var(--background_2)"

    const bgRed =
      process.env.NEXT_PUBLIC_NAME === "magnum"
        ? "var(--cta_1)"
        : "var(--icon_1)"

    const borderBlack =
      process.env.NEXT_PUBLIC_NAME === "magnum"
        ? "1px solid #ED1C24"
        : "1px solid var(--background_2)"

    const borderWhite =
      process.env.NEXT_PUBLIC_NAME === "magnum"
        ? "1px solid var(--text_2)"
        : "1px solid var(--text_1)"

    const borderRed =
      process.env.NEXT_PUBLIC_NAME === "magnum"
        ? "1px solid var(--cta_1)"
        : "1px solid var(--icon_1)"

    const switchBG = () => {
      switch (color) {
        case "red":
          return bgRed
        case "black":
          return bgBlack
        case "white":
          return bgWhite
        default:
          return "transparent"
      }
    }

    const switchBorder = () => {
      switch (color) {
        case "red":
          return borderRed
        case "black":
          return borderBlack
        case "white":
          return borderWhite
        default:
          return "transparent"
      }
    }

    return (
      <div
        style={{ background: switchBG(), border: switchBorder() }}
        className={wordBox({ color, size })}
      >
        {word}
      </div>
    )
  }

  const HowToPlay = (
    <div
      className={clsx(
        "bg-bg-3 ",
        isDesktop
          ? "my-0 h-[calc(100vh-56px)]"
          : "my-[10px] p-[15px] rounded-[10px] border border-cta-4"
      )}
    >
      <p
        className={clsx(
          "font-bold text-text-2 font-font-family-7",
          isDesktop ? "text-[40px]" : "text-base"
        )}
      >
        How To Play
      </p>
      <Description
        className={clsx(
          "text-text-1 font-medium font-font-family-6",
          isDesktop ? "text-base my-[15px] max-w-[436px]" : "text-sm my-[10px]"
        )}
        description={props?.wordle.subtitle_instruction}
      />
      <Description
        className={clsx(
          "text-text-2",
          isDesktop
            ? "text-base leading-[25px] pl-[24px] "
            : "text-sm leading-[24px] pl-[16px]"
        )}
        description={props?.wordle.desc_instruction}
      />
    </div>
  )

  const Rule = (
    <div
      className={clsx(
        "bg-bg-3",
        isDesktop
          ? "my-0 max-w-[437px] h-[calc(100vh-56px)]"
          : "my-[10px] p-[15px] rounded-[10px] border border-cta-4 "
      )}
    >
      <p
        className={clsx(
          "font-bold text-text-2 font-font-family-7",
          isDesktop ? "text-[40px]" : "text-base"
        )}
      >
        Game Rules
      </p>
      <div
        className={clsx(
          "flex gap-[5px] items-center justify-center bg-bg-1 rounded-[10px]",
          isDesktop ? "py-[46px] mt-[20px] mb-[10px] " : "py-[30px] my-[10px]"
        )}
      >
        <WordleBox word="R" color="red" />
        <WordleBox word="I" color="black" />
        <WordleBox word="G" color="white" />
        <WordleBox word="H" color="red" />
        <WordleBox word="T" color="black" />
      </div>
      <div
        className={clsx(
          "flex items-center ",
          isDesktop ? "gap-[10px]" : "gap-[8px]"
        )}
      >
        <div
          className={clsx(
            "flex gap-[2px] items-center",
            isDesktop ? "min-w-[60px]" : "min-w-[45px]"
          )}
        >
          <WordleBox word="R" color="red" size="small" />
          <WordleBox word="H" color="red" size="small" />
        </div>
        <p
          className={clsx(
            " text-text-2",
            isDesktop ? "text-base leading-[25px]" : "text-sm leading-[24px]"
          )}
        >
          {wordle.wordle_rules_1}
        </p>
      </div>
      <div
        className={clsx(
          "flex items-center",
          isDesktop ? "gap-[10px] my-[32px]" : "gap-[8px]"
        )}
      >
        <div
          className={clsx(
            "flex gap-[2px] items-center justify-center",
            isDesktop ? "min-w-[60px]" : "min-w-[45px]"
          )}
        >
          <WordleBox word="G" color="white" size="small" />
        </div>
        <p
          className={clsx(
            " text-text-2",
            isDesktop ? "text-base leading-[25px]" : "text-sm leading-[24px]"
          )}
        >
          {wordle.wordle_rules_2}
        </p>
      </div>
      <div
        className={clsx(
          "flex items-center ",
          isDesktop ? "gap-[10px]" : "gap-[8px]"
        )}
      >
        <div
          className={clsx(
            "flex gap-[2px] items-center justify-center",
            isDesktop ? "min-w-[60px]" : "min-w-[45px]"
          )}
        >
          <WordleBox word="I" color="black" size="small" />
          <WordleBox word="T" color="black" size="small" />
        </div>
        <p
          className={clsx(
            " text-text-2",
            isDesktop ? "text-base leading-[25px]" : "text-sm leading-[24px]"
          )}
        >
          {wordle.wordle_rules_3}
        </p>
      </div>
    </div>
  )

  const onGameStart = async () => {
    if (isTnc && !isTncChecked) {
      setLoading(true)
      const { data } = await ClientApiList.setTncEngagement({
        engagement_uuid: wordle?.engagement_uuid,
      })
      if (data?.code === 200) {
        setLoading(false)
        setPause(false)
        setIsTncChecked(true)
        setStage(2)
      }
    } else {
      setStage(2)
      setPause(false)
    }
    datalayer.push({
      event: "general_event",
      event_name: "click_mulai_sekarang_wordle",
      feature: "simple engagement",
      engagement_type: "games",
      engagement_name: "wordle",
      campaign_name: wordle?.campaign_name.toLowerCase(), // e.g all
      event_label: `wordle - ${wordle?.subtitle_introduction.toLowerCase()}`,
      level: 1,
      content_id: wordle?.engagement_uuid,
    })
  }

  const StartGameCta = (
    <ButtonAction
      intent={isTnc && !watch("isAgree") ? "primary_disable" : "primary"}
      data-testid="start-game-cta"
      className="w-full"
      onClick={() => {
        onGameStart()
      }}
      disabled={isTnc && !watch("isAgree")}
    >
      {pause ? "Lanjut Main" : "Mulai Sekarang"}
    </ButtonAction>
  )

  const wPointDesk =
    process.env.NEXT_PUBLIC_NAME === "amild" ? " w-[32px]" : " w-[24px]"
  const wPointMob =
    process.env.NEXT_PUBLIC_NAME === "amild" ? " w-[28px]" : " w-[16px]"

  const pointGet = wordle?.point && (
    <div
      className={clsx(
        "flex gap-[5px] items-center text-text-1 font-bold font-font-family-7",
        isDesktop ? "text-base" : "text-sm mt-[10px]"
      )}
    >
      {finished === 1 ? (
        <SuccessIcon />
      ) : (
        <div
          className={clsx(
            "relative aspect-square",
            isDesktop ? wPointDesk : wPointMob
          )}
        >
          <Image
            objectFit="cover"
            alt="icon"
            src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/${process.env.NEXT_PUBLIC_NAME === "amild" ? "reward-profile-effect.png" : "reward-profile.svg"}`}
          />
        </div>
      )}
      <span>{wordle?.point}</span> <span>Poin</span>
    </div>
  )

  const blackGradient =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe" ||
    process.env.NEXT_PUBLIC_NAME === "magnum"

  return (
    <>
      <div ref={landingRef} />
      <div className={clsx("relative w-full px-[10px]", isDesktop && "flex")}>
        <Link href={prevPath || "/"} passHref>
          <ButtonBack className="absolute top-[-10px] left-[6px]" />
        </Link>
        {!isDesktop && (
          <h2 className="text-center text-text-2 text-xl font-medium font-font-family-6">
            Wordle Challenge
          </h2>
        )}
        <div
          className={clsx(
            "relative w-full overflow-hidden rounded-[10px] bg-bg-1",
            isDesktop
              ? "mt-0 max-w-[838px] min-w-[638px] aspect-[638/604] rounded-r-none"
              : "mt-[30px] aspect-[355/267]"
          )}
        >
          <Image
            alt="wordle-cover"
            fill
            objectFit="cover"
            src={
              isDesktop
                ? wordle?.desktop_image_introduction_url
                : wordle?.mobile_image_introduction_url
            }
          />
        </div>
        <div
          className={clsx(
            "bg-bg-3 rounded-[10px] border border-cta-4 p-[15px] w-full",
            isDesktop ? "rounded-l-none py-[63px] px-[55px]" : "my-[10px]"
          )}
        >
          {isDesktop && (
            <div className="flex gap-[20px]">
              <p className="w-fit bg-cta-3 rounded-[10px] py-[10px] px-[15px] font-medium font-font-family-6 text-center text-text-1 text-sm tracking-[0.14px]">
                Wordle Challenge
              </p>
              {pointGet}
            </div>
          )}
          <p
            className={clsx(
              "font-bold text-text-2 font-font-family-7",
              isDesktop ? "text-[40px] mt-[20px]" : "text-base"
            )}
          >
            {props?.wordle.subtitle_introduction}
          </p>
          {!isDesktop && pointGet}
          <Description
            className={clsx(
              "text-text-2",
              isDesktop
                ? "text-xl leading-[32px] mt-[20px]"
                : "text-sm leading-[24px] mt-[10px]"
            )}
            description={props?.wordle.desc_introduction}
          />
          {isTnc && (
            <form
              method="POST"
              className="flex gap-[5px] mt-[10px] md:mb-[30px]"
            >
              <CheckBox
                register={register}
                name={"isAgree"}
                disabled={isTncChecked}
                isCenter={true}
                // value={isChecked}
                // options={{onChange:(e) => setIsChecked(e.target.value)}}
                className="top-[calc(50%_-_10px)]"
              />
              <div
                ref={linkRef}
                className="text-[12px] lg:text-[14px] font-medium underline leading-[18px] text-text-1"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(tnc),
                }}
              ></div>
            </form>
          )}
          {isDesktop && (
            <div className="w-full flex flex-col gap-[30px] mt-[30px]">
              <div className="flex gap-[10px]">
                <ButtonAction
                  intent="secondary"
                  className="w-fit px-[20px]"
                  onClick={() => setOpenHowTo(true)}
                >
                  <div className="flex gap-[5px] items-center">
                    <AddIcon
                      fillColor={
                        process.env.NEXT_PUBLIC_NAME === "magnum"
                          ? "fill-bg-5"
                          : "fill-icon-1"
                      }
                    />
                    <span
                      className={clsx(
                        isSampoernaKretek ? "bg-cta-1" : "bg-text-1",
                        "bg-clip-text"
                      )}
                    >
                      How To Play
                    </span>
                  </div>
                </ButtonAction>
                <ButtonAction
                  intent="secondary"
                  className="w-fit px-[20px]"
                  onClick={() => setOpenRule(true)}
                >
                  <div className="flex gap-[5px] items-center">
                    <AddIcon
                      fillColor={
                        process.env.NEXT_PUBLIC_NAME === "magnum"
                          ? "fill-bg-5"
                          : "fill-icon-1"
                      }
                    />
                    <span
                      className={clsx(
                        isSampoernaKretek ? "bg-cta-1" : "bg-text-1",
                        "bg-clip-text"
                      )}
                    >
                      Games Rules
                    </span>
                  </div>
                </ButtonAction>
              </div>
              <div className="w-full h-px bg-[#E6E6E6] " />
              <div className="w-[343px]">
                {loading ? (
                  <div className="w-full flex justify-center items-center">
                    <Spinner />
                  </div>
                ) : (
                  StartGameCta
                )}
              </div>
            </div>
          )}
        </div>
        {!isDesktop && HowToPlay}
        {!isDesktop && Rule}
        {!isDesktop && (
          <div
            className={clsx(
              "fixed z-[19] bottom-[56px] left-2/4 -translate-x-1/2 w-full px-[10px] pt-[80px] pb-[20px] ",
              blackGradient
                ? "bg-[linear-gradient(180deg,_rgba(0,0,0,0)_0,_#000_40%)]"
                : "bg-[linear-gradient(180deg,_rgba(255,255,255,0)_0,_#FFF_40%)]"
            )}
          >
            {loading ? (
              <div className="w-full flex justify-center items-center">
                <Spinner />
              </div>
            ) : (
              StartGameCta
            )}
          </div>
        )}
      </div>
      <PopupGame open={openHowTo} onClick={() => setOpenHowTo(false)}>
        {HowToPlay}
      </PopupGame>
      <PopupGame open={openRule} onClick={() => setOpenRule(false)}>
        {Rule}
      </PopupGame>
    </>
  )
}

LandingWordle.propTypes = {
  setStage: PropTypes.func,
  wordle: PropTypes.object,
  pause: PropTypes.bool,
  setPause: PropTypes.func,
  finished: PropTypes.func,
}

const AddIcon = ({ fillColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        className={fillColor}
        d="M9.99935 1.66699C5.40768 1.66699 1.66602 5.40866 1.66602 10.0003C1.66602 14.592 5.40768 18.3337 9.99935 18.3337C14.591 18.3337 18.3327 14.592 18.3327 10.0003C18.3327 5.40866 14.591 1.66699 9.99935 1.66699ZM13.3327 10.6253H10.6243V13.3337C10.6243 13.6753 10.341 13.9587 9.99935 13.9587C9.65768 13.9587 9.37435 13.6753 9.37435 13.3337V10.6253H6.66602C6.32435 10.6253 6.04102 10.342 6.04102 10.0003C6.04102 9.65866 6.32435 9.37533 6.66602 9.37533H9.37435V6.66699C9.37435 6.32533 9.65768 6.04199 9.99935 6.04199C10.341 6.04199 10.6243 6.32533 10.6243 6.66699V9.37533H13.3327C13.6743 9.37533 13.9577 9.65866 13.9577 10.0003C13.9577 10.342 13.6743 10.6253 13.3327 10.6253Z"
      />
    </svg>
  )
}

AddIcon.propTypes = {
  fillColor: PropTypes.string,
}
