"use client";
import React, { useEffect, useState } from "react";
import LandingSubmission from "./landing-submission";
import GameSumbission from "./game-submission";
import { ClientApiList } from "@/lib/api/client-service";
import PropTypes from "prop-types";
import AudioSubmission from "./audio-submission/audio-submission";

const SubmissionFile = (props) => {
  const [stage, setStage] = useState(1);
  const [game, setGame] = useState([]);
  const [finished, setFinished] = useState(0);
  const [isTnc, setIsTnc] = useState(false);
  const [isTncChecked, setIsTncChecked] = useState(false);
  const [tnc, setTnc] = useState("");
  const [audio, setAudio] = useState({});
  const propsStage = {
    stage,
    setStage,
    game,
    finished,
    isTnc,
    isTncChecked,
    tnc,
    setIsTnc,
    setIsTncChecked,
    setTnc,
    ...props,
  };

  const getDetail = async () => {
    const { data } = await ClientApiList.getEngagementDetail({
      type: props?.data?.type || "media-submission",
      engagement_uuid: props?.data?.engagement_uuid,
    });
    const datas = data?.data?.data;

    props?.data?.type === "audio"
      ? setAudio(datas?.audio)
      : setGame(datas?.submission);
    setFinished(datas?.finished);
    setIsTnc(datas?.is_tnc);
    setIsTncChecked(datas?.is_tnc_checked);
    setTnc(datas?.tnc);
  };

  useEffect(() => {
    if (stage === 1) getDetail();
    // eslint-disable-next-line
  }, [stage]);

  const contentView = () => {
    if (stage === 1) return <LandingSubmission {...propsStage} />;
    return props?.data?.type === "audio" ? (
      <AudioSubmission submission={audio} {...propsStage} />
    ) : (
      <GameSumbission {...propsStage} />
    );
  };

  return <section>{contentView()}</section>;
};

export default SubmissionFile;

LandingSubmission.propTypes = {
  data: PropTypes.object,
};
