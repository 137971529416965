"use client"
import React, { useEffect, useState } from "react"
// import YourChallengeSection from '../user-profile-leaderboard/your-challenge-section';
import TopUserList from "../user-profile-leaderboard/components/top-user-list"
import RestUserList from "../user-profile-leaderboard/components/rest-user-list"
import BackBanner from "../shared/back-banner"
import ButtonBack from "../shared/button-back/button-back"
import { Image } from "@/components/shared/image/image"
import { ClientApiList } from "@/lib/api/client-service"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { urlAsset } from "@/helper/utils";

export function UserProfileLeaderboard({
  bg_img_url_desktop,
  bg_img_url_mobile,
}) {
  const isMobile = useIsMobile()
  const [userLeaderboard, setUserLeaderboard] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const topUsers = userLeaderboard?.leaderboard.slice(0, 3) || []
  const restUsers = userLeaderboard?.leaderboard.slice(3) || []

  const handleGetData = async () => {
    // setIsLoading(true)
    try {
      const { data } = await ClientApiList.getUserLeaderboard()
      // console.log(data, "a data");
      setUserLeaderboard({ ...data.data.data })
      setIsLoading(false)
    } catch (err) {
      console.log("[Error]", err)
    }
  }

  useEffect(() => {
    handleGetData()
  }, [])

  // console.log(userLeaderboard);

  return (
    <>
      <BackBanner
        className="lg:hidden"
        imgClass={"object-center"}
        bgImg={isMobile ? bg_img_url_mobile : bg_img_url_desktop}
      ></BackBanner>
      <ButtonBack className="z-[4]" />
      <div className="lg:w-full h-full lg:max-w-[50%] relative z-[3] py-[19px] px-[10px] pb-[7px] lg:pb-0 lg:p-[15px] lg:pl-[10px]">
        <div className="text-[20px] font-medium font-font-family-6 text-center text-black leading-[27px] lg:hidden w-fit mx-auto text-text-2">
          Leaderboard
        </div>
        <div className="mt-[320px] lg:mt-[0]">
          <div className="font-font-family-3 relative bg-bg-5 overflow-hidden text-[16px] leading-[14.9px] rounded-t-[10px] lg:rounded-t-[15px] text-center text-white pt-[22.28px] pb-[42.7px] lg:pt-[22px] lg:pb-[47px] lg:text-[24px] lg:leading-[32px] mb-[-24px] lg:mb-[-30px] max-h-[100px]">
            <Image
              className="absolute inset-0 z-[0] w-[100vw]"
              alt="cover"
              fill
              style={{ objectFit: "cover" }}
              src={`${urlAsset()}/leaderboard/bg-header-${isMobile ? "mobile" : "desktop"}.png`}
            />
            Active Leaderboard
          </div>
          <div className="relative bg-bg-2 overflow-hidden py-[20px] lg:p-[30px] px-[15px] rounded-[10px] lg:rounded-[15px] mb-[30px] lg:mb-0">
            <Image
              className="absolute inset-0 z-[0] w-full"
              alt="cover"
              fill
              style={{ objectFit: "cover" }}
              src={`${urlAsset()}/leaderboard/bg-ranker-${isMobile ? "mobile" : "desktop"}.png`}
            />
            <div className="relative overflow-hidden z-[1] text-text-2 rounded-[5px] text-[12px] lg:text-[16px] leading-[18px] lg:leading-[22px] tracking-[0.25px] font-bold font-font-family-7 py-[10px] lg:py-[20px] lg:mx-auto text-center mb-[20px] lg:mb-[30px] max-w-[494px] mx-auto">
              <div
                style={{ background: "var(--text_1)", opacity: "0.25" }}
                className="absolute inset-0 z-[-1]"
              ></div>
              {process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "Kamu" : "Lo"} ada
              di peringkat{" "}
              <span className="text-text-1 uppercase text-[20px] leading-[28px] lg:text-[24px] lg:leading-[32px]">
                #{userLeaderboard?.position}
              </span>
            </div>
            <TopUserList isLoading={isLoading} topUsers={topUsers} />
            <RestUserList
              isLoading={isLoading}
              restUserList={restUsers}
              userPos={userLeaderboard?.position}
            />
            <div className="relative z-[1] flex items-center gap-[5px] md:gap-[10px] bg-transparent max-w-[637px] mx-auto">
              {/* <div className="max-w-[20px] w-[100%] max-h-[20px] relative"> */}
              <Image
                className="max-w-[20px] max-h-[20px]"
                src={`${urlAsset()}/user-profile/info.svg`}
                alt="info"
                objectFit="cover"
              />
              {/* </div> */}
              <div className="font-medium font-font-family-6 text-[12px] md:text-[16px] leading-[16px] md:leading-[22px] text-text-1 italic">
                <>
                  Ini adalah total poin dari seluruh aktivitas{" "}
                  {process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"}{" "}
                  di {String(process.env.NEXT_PUBLIC_NAME).toLocaleUpperCase()}
                  .ID. Berbeda dengan poin <i>profile</i> yang digunakan untuk{" "}
                  <i>redeem rewards</i>
                </>
              </div>
            </div>
          </div>
        </div>
        {/* <YourChallengeSection /> */}
      </div>
    </>
  )
}
