"use client"
import React, { useState, useEffect, Suspense } from "react"
import Link from "@/components/shared/link/link"
import { usePathname, useRouter, useSearchParams } from "next/navigation"

// Custom Comp. Imports
import ButtonAction from "@/components/shared/button-action/button-action"
import PopupAction from "@/components/shared/popup-action/popup-action"
import { ClientApiList } from "@/lib/api/client-service"
import { Image } from "@/components/shared/image/image"
import PropTypes from "prop-types"
import UseQueryParams from "@/hooks/use-query-params"
import { urlAsset } from "@/helper/utils";

function DeleteAccountPopupFallback() {
  return <div>Fallback</div>
}

function SuspendedDeleteAccountPopup({
  getValues,
  resetForm,
  setError,
  setFocus,
}) {
  const { replace } = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const [isShowing, setIsShowing] = useState(false)
  const QUERYPARAM = "showDeleteAccount"
  const { handleFilterQuery } = UseQueryParams()

  const onSubmit = async () => {
    const data = getValues()
    try {
      const response = await ClientApiList.updateAccountSetting({ ...data })
      if (response.status === 200) {
        resetForm()
        window.location.assign('/delete-account?logout=true');
        // replace(`/delete-account?logout=true`, { reload: true })
      } else {
        if (response.status === 401) replace("/")
        setError("login_name", { message: response?.error.message })
        setError("password", { message: response?.error.message })
        setFocus("login_name")
        handleFilterQuery(QUERYPARAM)
      }
    } catch (err) {
      if (err.status === 401) window.location.reload()
      setError("login_name", { message: err?.error.message })
      setError("password", { message: err?.error.message })
      setFocus("login_name")
      handleFilterQuery(QUERYPARAM)
    }
  }

  const onCancel = () => {
    handleFilterQuery(QUERYPARAM)
  }

  const hrefParse = () => {
    const current = new URLSearchParams(Array.from(searchParams.entries())) // -> has to use this form

    return {
      pathname,
      query: {
        ...Object.fromEntries(current.entries()),
        showDeleteAccount: false,
      },
    }
  }

  useEffect(() => {
    setIsShowing(searchParams.get(QUERYPARAM) === "true")
  }, [searchParams])

  const user = process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"

  return (
    <PopupAction isShowing={isShowing} href={hrefParse()} zIndex="z-[20]">
      <div className="max-w-[304px]">
        <div className="relative rounded-[10px] w-[304px] h-[177px] bg-bg-1 mb-[20px] flex items-center justify-center">
          <Image
            className="absolute inset-0"
            alt="delete"
            src={`${urlAsset()}/profile-edit/delete-popup.png`}
          />
        </div>
        <div className="text-[20px] lg:text-[19px] font-black font-font-family-8 mb-[5px] leading-[27px] capitalize text-text-2">
          {user} Akan Menghapus Akun {user}
        </div>
        <div className="text-[14px] lg:text-[16px] leading-[20px] lg:leading-[25px] mb-[20px] text-text-2">
          Aksi ini tidak dapat di batalkan dan semua data {user} bakal hilang
          dari database kami. Tekan Batal untuk membatalkan aksi bila {user}{" "}
          tidak yakin.
        </div>
        <div className="flex items-center justify-between gap-[10px]">
          <Link className="block w-[100%]" href={hrefParse()}>
            <ButtonAction
              className="py-[15px] px-[20px] leading-[20px]"
              intent="secondary"
              onClick={onCancel}
              type="reset"
              value="Reset"
            >
              Batal
            </ButtonAction>
          </Link>
          <ButtonAction
            className="py-[15px] px-[20px] leading-[20px]"
            intent="primary"
            onClick={onSubmit}
            type="submit"
            value="Submit"
          >
            Hapus Akun
          </ButtonAction>
        </div>
      </div>
    </PopupAction>
  )
}

export default function DeleteAccountPopup(props) {
  return (
    <Suspense fallback={<DeleteAccountPopupFallback />}>
      <SuspendedDeleteAccountPopup {...props} />
    </Suspense>
  )
}

SuspendedDeleteAccountPopup.propTypes = {
  getValues: PropTypes.func,
  resetForm: PropTypes.func,
  setError: PropTypes.func,
  setFocus: PropTypes.func,
}
