import PropTypes from "prop-types";
const { motion } = require("framer-motion");

export const IconUploadAudio = ({ className, width = 40, height = 40 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9987 25.3332L19.9987 17.3332M19.9987 17.3332L15.9987 19.9998M19.9987 17.3332L23.9987 19.9998M34.6654 23.9998C34.6654 21.0543 32.2776 18.6665 29.332 18.6665C29.3005 18.6665 29.2697 18.6668 29.2383 18.6673C28.5917 14.1439 24.7009 10.6665 19.9987 10.6665C16.2698 10.6665 13.0523 12.8532 11.5573 16.0143C8.08145 16.2418 5.33203 19.1331 5.33203 22.6665C5.33203 26.3484 8.3168 29.3332 11.9987 29.3332H29.332C32.2776 29.3332 34.6654 26.9454 34.6654 23.9998Z"
        className={className ?? "stroke-icon-1"}
        strokeWidth="2.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
IconUploadAudio.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export const IconUpload = ({ width = 25, height = 24 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M9.98047 13L12.9805 9L15.9805 13.5V12H19.9805V5C19.9805 3.897 19.0835 3 17.9805 3H4.98047C3.87747 3 2.98047 3.897 2.98047 5V17C2.98047 18.103 3.87747 19 4.98047 19H12.9805V15H5.98047L8.98047 11L9.98047 13Z"
        className="fill-icon-1"
      />
      <path
        d="M19.9805 14H17.9805V17H14.9805V19H17.9805V22H19.9805V19H22.9805V17H19.9805V14Z"
        className="fill-icon-1"
      />
    </svg>
  );
};
IconUpload.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export const IconTrash = ({ className, width = 40, height = 40 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.0697 13.23C27.4597 13.07 25.8497 12.95 24.2297 12.86V12.85L24.0097 11.55C23.8597 10.63 23.6397 9.25 21.2997 9.25H18.6797C16.3497 9.25 16.1297 10.57 15.9697 11.54L15.7597 12.82C14.8297 12.88 13.8997 12.94 12.9697 13.03L10.9297 13.23C10.5097 13.27 10.2097 13.64 10.2497 14.05C10.2897 14.46 10.6497 14.76 11.0697 14.72L13.1097 14.52C18.3497 14 23.6297 14.2 28.9297 14.73C28.9597 14.73 28.9797 14.73 29.0097 14.73C29.3897 14.73 29.7197 14.44 29.7597 14.05C29.7897 13.64 29.4897 13.27 29.0697 13.23Z"
      className={className ?? "fill-icon-1"}
    />
    <path
      d="M27.2317 16.14C26.9917 15.89 26.6617 15.75 26.3217 15.75H13.6817C13.3417 15.75 13.0017 15.89 12.7717 16.14C12.5417 16.39 12.4117 16.73 12.4317 17.08L13.0517 27.34C13.1617 28.86 13.3017 30.76 16.7917 30.76H23.2117C26.7017 30.76 26.8417 28.87 26.9517 27.34L27.5717 17.09C27.5917 16.73 27.4617 16.39 27.2317 16.14ZM21.6617 25.75H18.3317C17.9217 25.75 17.5817 25.41 17.5817 25C17.5817 24.59 17.9217 24.25 18.3317 24.25H21.6617C22.0717 24.25 22.4117 24.59 22.4117 25C22.4117 25.41 22.0717 25.75 21.6617 25.75ZM22.5017 21.75H17.5017C17.0917 21.75 16.7517 21.41 16.7517 21C16.7517 20.59 17.0917 20.25 17.5017 20.25H22.5017C22.9117 20.25 23.2517 20.59 23.2517 21C23.2517 21.41 22.9117 21.75 22.5017 21.75Z"
      className={className ?? "fill-icon-1"}
    />
  </svg>
);
IconTrash.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export const IconClose = ({ className, width = 20, height = 20 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.83594 5.83301L14.1693 14.1663M5.83594 14.1663L14.1693 5.83301"
        className={className ?? "stroke-icon-2"}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
IconClose.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export const IconCloseCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <circle
        cx="24"
        cy="24"
        r="19.75"
        fill="url(#paint0_linear_4840_16586)"
        stroke="url(#paint1_linear_4840_16586)"
        strokeWidth="0.5"
      />
      <path
        d="M16.3891 16.4237C16.6386 16.1751 16.9768 16.0354 17.3294 16.0354C17.682 16.0354 18.0202 16.1751 18.2697 16.4237L23.9823 22.1243L29.6948 16.4237C29.8575 16.2489 30.0643 16.1212 30.2936 16.0539C30.5229 15.9866 30.7662 15.9822 30.9977 16.0413C31.2293 16.1004 31.4406 16.2207 31.6094 16.3896C31.7782 16.5585 31.8982 16.7696 31.9568 17.0009C32.0161 17.2317 32.012 17.4742 31.9448 17.7028C31.8777 17.9315 31.7501 18.1379 31.5754 18.3003L25.8628 24.0009L31.5754 29.7014C31.7505 29.8637 31.8786 30.0701 31.946 30.2989C32.0134 30.5278 32.0178 30.7705 31.9586 31.0016C31.8994 31.2326 31.7788 31.4435 31.6096 31.6119C31.4404 31.7804 31.2287 31.9001 30.997 31.9586C30.7657 32.0178 30.5227 32.0136 30.2936 31.9467C30.0644 31.8797 29.8576 31.7523 29.6948 31.578L23.9823 25.8774L18.2697 31.578C18.0173 31.8124 17.6837 31.94 17.3389 31.9341C16.9942 31.9282 16.6652 31.7891 16.4211 31.5461C16.1776 31.3025 16.0382 30.9742 16.0323 30.6302C16.0263 30.2862 16.1542 29.9533 16.3891 29.7014L22.1017 24.0009L16.3891 18.3003C16.14 18.0513 16 17.7139 16 17.362C16 17.0102 16.14 16.6727 16.3891 16.4237Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4840_16586"
          x1="50.5"
          y1="-1.5"
          x2="13.5833"
          y2="41.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.302701" stopColor="white" stopOpacity="0.471684" />
          <stop offset="1" stopColor="white" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4840_16586"
          x1="6.5"
          y1="41"
          x2="41"
          y2="6.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.735223" stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0.55" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const IconCloseSquare = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M0.980469 5C0.980469 2.23858 3.21904 0 5.98047 0H19.9805C22.7419 0 24.9805 2.23858 24.9805 5V19C24.9805 21.7614 22.7419 24 19.9805 24H5.98047C3.21904 24 0.980469 21.7614 0.980469 19V5Z"
        fill="#5F6368"
        fillOpacity="0.25"
      />
      <path
        d="M8.73828 7.75732L17.2236 16.2426"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.73738 16.2426L17.2227 7.75732"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconErrors = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect x="5" y="5" width="10" height="10" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.61326 2.18848C7.28174 2.1879 6.96357 2.31904 6.72874 2.55304L2.8811 6.38721C2.64627 6.62122 2.51402 6.93893 2.51343 7.27045L2.50391 12.7023C2.50333 13.0338 2.63447 13.352 2.86847 13.5868L6.70264 17.4345C6.93665 17.6693 7.25436 17.8016 7.58588 17.8021L13.0177 17.8117C13.3493 17.8122 13.6674 17.6811 13.9023 17.4471L17.7499 13.6129C17.9847 13.3789 18.117 13.0612 18.1176 12.7297L18.1271 7.29783C18.1277 6.96631 17.9965 6.64814 17.7625 6.41331L13.9284 2.56567C13.6944 2.33084 13.3766 2.19859 13.0451 2.198L7.61326 2.18848ZM7.49432 6.09382L6.40925 7.17889L9.23043 10.0001L6.40925 12.8213L7.49432 13.9063L10.3155 11.0851L13.1367 13.9063L14.2218 12.8213L11.4006 10.0001L14.2218 7.17889L13.1367 6.09382L10.3155 8.915L7.49432 6.09382Z"
        fill="#FD2A29"
      />
    </svg>
  );
};

export const IconPlus = ({ animate }) => {
  const iconVariants = {
    open: { rotate: 90 },
    closed: { rotate: 0 },
  };

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11837_156503)">
        <path
          d="M4 8H12"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="stroke-icon-1"
        />
        <motion.path
          initial={false}
          animate={animate ?? "closed"}
          variants={iconVariants}
          transition={{ duration: 0.2 }}
          d="M8 12V4"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="stroke-icon-1"
        />
      </g>
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="1.5"
        className="stroke-icon-1"
      />
      <defs>
        <clipPath id="clip0_11837_156503">
          <rect width="16" height="16" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
IconPlus.propTypes = {
  animate: PropTypes.string,
};

export const IconMinus = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_12484_79215)">
      <path
        d="M4 8H12"
        stroke="#ED1C24"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#ED1C24" />
    <defs>
      <clipPath id="clip0_12484_79215">
        <rect width="16" height="16" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const IconArrowDown = ({ width = 24, height = 24, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 9L12 16L5 9"
      className={className ?? "stroke-icon-2"}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
IconArrowDown.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export const IconJumpStepBackward = ({
  className,
  width = 24,
  height = 25,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9606 11.3301H12.9006L12.1406 13.6201H14.4306C15.2706 13.6201 15.9606 14.3001 15.9606 15.1501C15.9606 15.9901 15.2806 16.6801 14.4306 16.6801H12.1406"
      className={className ?? "stroke-icon-1"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.53906 16.67V11.3301L8.03906 13.0001"
      className={className ?? "stroke-icon-1"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0195 4.96997L11.9995 2.5"
      className={className ?? "stroke-icon-1"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.90936 8.29974C3.79936 9.77974 3.10938 11.6097 3.10938 13.6097C3.10938 18.5197 7.08939 22.4998 11.9994 22.4998C16.9094 22.4998 20.8894 18.5197 20.8894 13.6097C20.8894 8.69974 16.9094 4.71973 11.9994 4.71973C11.3194 4.71973 10.6594 4.80978 10.0194 4.95978"
      className={className ?? "stroke-icon-1"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
IconJumpStepBackward.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export const IconJumpStepForward = ({ className, width = 25, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4606 10.8301H13.4006L12.6406 13.1201H14.9306C15.7706 13.1201 16.4606 13.8001 16.4606 14.6501C16.4606 15.4901 15.7806 16.1801 14.9306 16.1801H12.6406"
      className={className ?? "stroke-icon-1"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0391 16.17V10.8301L8.53906 12.5001"
      className={className ?? "stroke-icon-1"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3711 4.46997L12.3911 2"
      className={className ?? "stroke-icon-1"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.4813 7.79974C20.5913 9.27974 21.2812 11.1097 21.2812 13.1097C21.2812 18.0197 17.3012 21.9998 12.3912 21.9998C7.48124 21.9998 3.50122 18.0197 3.50122 13.1097C3.50122 8.19974 7.48124 4.21973 12.3912 4.21973C13.0712 4.21973 13.7312 4.30978 14.3712 4.45978"
      className={className ?? "stroke-icon-1"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
IconJumpStepForward.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export const IconMessageText = ({ className, width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2H8C4 2 2 4 2 8V21C2 21.55 2.45 22 3 22H16C20 22 22 20 22 16V8C22 4 20 2 16 2Z"
      className={className ?? "stroke-icon-1"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 9.5H17"
      className={className ?? "stroke-icon-1"}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 14.5H14"
      className={className ?? "stroke-icon-1"}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
IconMessageText.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export const IconMessageTextFilled = ({
  className,
  width = 24,
  height = 24,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2H8C4 2 2 4 2 8V21C2 21.55 2.45 22 3 22H16C20 22 22 20 22 16V8C22 4 20 2 16 2ZM14 15.25H7C6.59 15.25 6.25 14.91 6.25 14.5C6.25 14.09 6.59 13.75 7 13.75H14C14.41 13.75 14.75 14.09 14.75 14.5C14.75 14.91 14.41 15.25 14 15.25ZM17 10.25H7C6.59 10.25 6.25 9.91 6.25 9.5C6.25 9.09 6.59 8.75 7 8.75H17C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25Z"
      className={className ?? "fill-icon-1"}
    />
  </svg>
);
IconMessageTextFilled.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export const IconPlay = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_39_3858)">
      <g filter="url(#filter0_d_39_3858)">
        <rect
          x="4.57495"
          y="4.5719"
          width="54.8571"
          height="54.8571"
          rx="27.4286"
          className="fill-icon-1"
        />
      </g>
      <path
        d="M26.2876 21.7125V42.284L43.4305 31.9982L26.2876 21.7125Z"
        className="fill-icon-4"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_39_3858"
        x="-5.02505"
        y="-1.8281"
        width="70.8572"
        height="70.8572"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1.6" dy="1.6" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_39_3858"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_39_3858"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_39_3858">
        <rect
          width="64"
          height="64"
          rx="24"
          className={
            process.env.NEXT_PUBLIC_NAME !== "amild"
              ? "fill-icon-2"
              : "fill-icon-4"
          }
        />
      </clipPath>
    </defs>
  </svg>
);

export const IconPause = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4.57495"
      y="4.57178"
      width="54.8571"
      height="54.8571"
      rx="27.4286"
      className="fill-icon-1"
    />
    <path
      d="M28 22.3999H26C25.4696 22.3999 24.9609 22.6106 24.5858 22.9857C24.2107 23.3608 24 23.8695 24 24.3999V40.3999C24 40.9303 24.2107 41.439 24.5858 41.8141C24.9609 42.1892 25.4696 42.3999 26 42.3999H28C28.5304 42.3999 29.0391 42.1892 29.4142 41.8141C29.7893 41.439 30 40.9303 30 40.3999V24.3999C30 23.8695 29.7893 23.3608 29.4142 22.9857C29.0391 22.6106 28.5304 22.3999 28 22.3999V22.3999ZM38 22.3999H36C35.4696 22.3999 34.9609 22.6106 34.5858 22.9857C34.2107 23.3608 34 23.8695 34 24.3999V40.3999C34 40.9303 34.2107 41.439 34.5858 41.8141C34.9609 42.1892 35.4696 42.3999 36 42.3999H38C38.5304 42.3999 39.0391 42.1892 39.4142 41.8141C39.7893 41.439 40 40.9303 40 40.3999V24.3999C40 23.8695 39.7893 23.3608 39.4142 22.9857C39.0391 22.6106 38.5304 22.3999 38 22.3999V22.3999Z"
      className={
        process.env.NEXT_PUBLIC_NAME !== "amild" ? "fill-icon-2" : "fill-icon-4"
      }
    />
  </svg>
);

export const IconVolumeHigh = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 9.99979V13.9998C2 15.9998 3 16.9998 5 16.9998H6.43C6.8 16.9998 7.17 17.1098 7.49 17.2998L10.41 19.1298C12.93 20.7098 15 19.5598 15 16.5898V7.40979C15 4.42979 12.93 3.28979 10.41 4.86979L7.49 6.69979C7.17 6.88979 6.8 6.99979 6.43 6.99979H5C3 6.99979 2 7.99979 2 9.99979Z"
      className="stroke-icon-1"
      strokeWidth="1.5"
    />
    <path
      d="M18 8C19.78 10.37 19.78 13.63 18 16"
      className="stroke-icon-1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8301 5.5C22.7201 9.35 22.7201 14.65 19.8301 18.5"
      className="stroke-icon-1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
IconVolumeHigh.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export const IconVolumeLow = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.32996 10V14C3.32996 16 4.32996 17 6.32996 17H7.75996C8.12996 17 8.49996 17.11 8.81996 17.3L11.74 19.13C14.26 20.71 16.33 19.56 16.33 16.59V7.41003C16.33 4.43003 14.26 3.29003 11.74 4.87003L8.81996 6.70003C8.49996 6.89003 8.12996 7.00003 7.75996 7.00003H6.32996C4.32996 7.00003 3.32996 8.00003 3.32996 10Z"
      className="stroke-icon-1"
      strokeWidth="1.5"
    />
    <path
      d="M19.33 8C21.11 10.37 21.11 13.63 19.33 16"
      className="stroke-icon-1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
IconVolumeLow.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export const IconVolumeMute = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 10.1599V14.1599C2 16.1599 3 17.1599 5 17.1599H6.43C6.8 17.1599 7.17 17.2699 7.49 17.4599L10.41 19.2899C12.93 20.8699 15 19.7199 15 16.7499V7.56995C15 4.58995 12.93 3.44995 10.41 5.02995L7.49 6.85995C7.17 7.04995 6.8 7.15995 6.43 7.15995H5C3 7.15995 2 8.15995 2 10.1599Z"
      className="stroke-icon-1"
      strokeWidth="1.5"
    />
    <path
      d="M21.9991 14.1192L18.0391 10.1592"
      className="stroke-icon-1"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.96 10.1992L18 14.1592"
      className="stroke-icon-1"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
IconVolumeMute.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export const IconVolumeHighFill = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0003 16.7503C17.8403 16.7503 17.6903 16.7003 17.5503 16.6003C17.2203 16.3503 17.1503 15.8803 17.4003 15.5503C18.9703 13.4603 18.9703 10.5403 17.4003 8.45027C17.1503 8.12027 17.2203 7.65027 17.5503 7.40027C17.8803 7.15027 18.3503 7.22027 18.6003 7.55027C20.5603 10.1703 20.5603 13.8303 18.6003 16.4503C18.4503 16.6503 18.2303 16.7503 18.0003 16.7503Z"
      className="fill-icon-1"
    />
    <path
      d="M19.8304 19.2503C19.6704 19.2503 19.5204 19.2003 19.3804 19.1003C19.0504 18.8503 18.9804 18.3803 19.2304 18.0503C21.9004 14.4903 21.9004 9.51027 19.2304 5.95027C18.9804 5.62027 19.0504 5.15027 19.3804 4.90027C19.7104 4.65027 20.1804 4.72027 20.4304 5.05027C23.5004 9.14027 23.5004 14.8603 20.4304 18.9503C20.2904 19.1503 20.0604 19.2503 19.8304 19.2503Z"
      className="fill-icon-1"
    />
    <path
      d="M14.02 3.77972C12.9 3.15972 11.47 3.31972 10.01 4.22972L7.09 6.05972C6.89 6.17972 6.66 6.24972 6.43 6.24972H5.5H5C2.58 6.24972 1.25 7.57972 1.25 9.99972V13.9997C1.25 16.4197 2.58 17.7497 5 17.7497H5.5H6.43C6.66 17.7497 6.89 17.8197 7.09 17.9397L10.01 19.7697C10.89 20.3197 11.75 20.5897 12.55 20.5897C13.07 20.5897 13.57 20.4697 14.02 20.2197C15.13 19.5997 15.75 18.3097 15.75 16.5897V7.40972C15.75 5.68972 15.13 4.39972 14.02 3.77972Z"
      className="fill-icon-1"
    />
  </svg>
);
IconVolumeHighFill.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export const IconVolumeLowFill = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3304 16.7503C19.1704 16.7503 19.0204 16.7003 18.8804 16.6003C18.5504 16.3503 18.4804 15.8803 18.7304 15.5503C20.3004 13.4603 20.3004 10.5403 18.7304 8.45027C18.4804 8.12027 18.5504 7.65027 18.8804 7.40027C19.2104 7.15027 19.6804 7.22027 19.9304 7.55027C21.9004 10.1703 21.9004 13.8303 19.9304 16.4503C19.7904 16.6503 19.5604 16.7503 19.3304 16.7503Z"
      className="fill-icon-1"
    />
    <path
      d="M15.3501 3.77972C14.2301 3.15972 12.8001 3.31972 11.3401 4.22972L8.42008 6.05972C8.22008 6.17972 7.99008 6.24972 7.76008 6.24972H6.83008H6.33008C3.91008 6.24972 2.58008 7.57972 2.58008 9.99972V13.9997C2.58008 16.4197 3.91008 17.7497 6.33008 17.7497H6.83008H7.76008C7.99008 17.7497 8.22008 17.8197 8.42008 17.9397L11.3401 19.7697C12.2201 20.3197 13.0801 20.5897 13.8801 20.5897C14.4001 20.5897 14.9001 20.4697 15.3501 20.2197C16.4601 19.5997 17.0801 18.3097 17.0801 16.5897V7.40972C17.0801 5.68972 16.4601 4.39972 15.3501 3.77972Z"
      className="fill-icon-1"
    />
  </svg>
);
IconVolumeLowFill.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export const IconVolumeMuteFill = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5295 13.4197L21.0795 11.9697L22.4795 10.5697C22.7695 10.2797 22.7695 9.79969 22.4795 9.50969C22.1895 9.21969 21.7095 9.21969 21.4195 9.50969L20.0195 10.9097L18.5695 9.45969C18.2795 9.16969 17.7995 9.16969 17.5095 9.45969C17.2195 9.74969 17.2195 10.2297 17.5095 10.5197L18.9595 11.9697L17.4695 13.4597C17.1795 13.7497 17.1795 14.2297 17.4695 14.5197C17.6195 14.6697 17.8095 14.7397 17.9995 14.7397C18.1895 14.7397 18.3795 14.6697 18.5295 14.5197L20.0195 13.0297L21.4695 14.4797C21.6195 14.6297 21.8095 14.6997 21.9995 14.6997C22.1895 14.6997 22.3795 14.6297 22.5295 14.4797C22.8195 14.1897 22.8195 13.7197 22.5295 13.4197Z"
      className="fill-icon-1"
    />
    <path
      d="M14.02 3.77972C12.9 3.15972 11.47 3.31972 10.01 4.22972L7.09 6.05972C6.89 6.17972 6.66 6.24972 6.43 6.24972H5.5H5C2.58 6.24972 1.25 7.57972 1.25 9.99972V13.9997C1.25 16.4197 2.58 17.7497 5 17.7497H5.5H6.43C6.66 17.7497 6.89 17.8197 7.09 17.9397L10.01 19.7697C10.89 20.3197 11.75 20.5897 12.55 20.5897C13.07 20.5897 13.57 20.4697 14.02 20.2197C15.13 19.5997 15.75 18.3097 15.75 16.5897V7.40972C15.75 5.68972 15.13 4.39972 14.02 3.77972Z"
      className="fill-icon-1"
    />
  </svg>
);
IconVolumeMuteFill.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export const IconLoopOff = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0002 3L16.4402 5.34003L8.49023 5.32001C4.92023 5.32001 1.99023 8.25003 1.99023 11.84C1.99023 13.63 2.72024 15.26 3.90024 16.44"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-icon-1"
    />
    <path
      d="M10.0005 20.9996L7.56055 18.6595L15.5106 18.6796C19.0806 18.6796 22.0106 15.7495 22.0106 12.1595C22.0106 10.3695 21.2806 8.73957 20.1006 7.55957"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-icon-1"
    />
  </svg>
);

export const IconLoop = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0002 3L16.4402 5.34003L8.49023 5.32001C4.92023 5.32001 1.99023 8.25003 1.99023 11.84C1.99023 13.63 2.72024 15.26 3.90024 16.44"
      className="stroke-icon-1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.9986 20.9996L7.55859 18.6595L15.5086 18.6796C19.0786 18.6796 22.0086 15.7495 22.0086 12.1595C22.0086 10.3695 21.2786 8.73957 20.0986 7.55957"
      className="stroke-icon-1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.25 14.67V9.33008L10.75 11.0001"
      className="stroke-icon-1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconWarning = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="12" y="9" width="5" height="14" fill="white" />
    <path
      d="M25.1684 21.9481L15.9596 4.84687C15.3069 3.63438 13.5681 3.63438 12.9149 4.84687L3.70664 21.9481C3.56492 22.2113 3.49386 22.5068 3.50042 22.8057C3.50697 23.1046 3.5909 23.3966 3.74402 23.6534C3.89714 23.9101 4.11421 24.1228 4.37405 24.2706C4.63389 24.4185 4.92762 24.4964 5.22657 24.4968H23.6457C23.9449 24.4969 24.239 24.4193 24.4992 24.2716C24.7594 24.124 24.9769 23.9114 25.1303 23.6545C25.2837 23.3977 25.3679 23.1055 25.3746 22.8064C25.3812 22.5073 25.3102 22.2115 25.1684 21.9481ZM14.4375 21.8492C14.2238 21.8492 14.0148 21.7859 13.8371 21.6671C13.6594 21.5484 13.5209 21.3796 13.4391 21.1821C13.3573 20.9847 13.3359 20.7674 13.3776 20.5578C13.4193 20.3481 13.5222 20.1556 13.6734 20.0045C13.8245 19.8533 14.0171 19.7504 14.2267 19.7087C14.4363 19.667 14.6536 19.6884 14.851 19.7702C15.0485 19.852 15.2173 19.9905 15.336 20.1682C15.4548 20.3459 15.5182 20.5549 15.5182 20.7686C15.5182 21.0552 15.4043 21.3301 15.2016 21.5327C14.999 21.7354 14.7241 21.8492 14.4375 21.8492ZM15.6111 10.9806L15.3009 17.5726C15.3009 17.8019 15.2099 18.0218 15.0477 18.1839C14.8856 18.346 14.6657 18.4371 14.4364 18.4371C14.2071 18.4371 13.9872 18.346 13.8251 18.1839C13.663 18.0218 13.5719 17.8019 13.5719 17.5726L13.2618 10.9833C13.2548 10.8259 13.2796 10.6686 13.3347 10.521C13.3898 10.3733 13.474 10.2382 13.5824 10.1238C13.6909 10.0094 13.8212 9.918 13.9657 9.85504C14.1102 9.79207 14.2658 9.75884 14.4235 9.75733H14.4348C14.5935 9.75725 14.7505 9.78932 14.8965 9.8516C15.0424 9.91388 15.1742 10.0051 15.284 10.1197C15.3937 10.2343 15.479 10.37 15.5349 10.5185C15.5908 10.667 15.6159 10.8253 15.6089 10.9839L15.6111 10.9806Z"
      fill="#FF9F0A"
    />
  </svg>
);

export const IconAdd = ({ className, width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 8H12"
      className={className || "stroke-cta-2"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 12V4"
      className={className || "stroke-cta-2"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
