"use client"
import React, { useState, useEffect, Suspense, useRef } from "react"
import PropTypes from "prop-types"
import { useSearchParams } from "next/navigation"
import { SwiperSlide } from "swiper/react"

// Custom comp. imports
import HistoryEXPCard from "../mgm/history-exp-card"
import CategoryCard from "../shared/category-card/category-card"
import { SwiperComp } from "../swiper/swiper"
import { ClientApiList } from "@/lib/api/client-service"
import { Image } from "../shared/image/image"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { useDatalayer } from "@/hooks/use-datalayer"
import { urlAsset } from "@/helper/utils";

const MGMHistoryEXPFallback = () => {
  return <div>Fallback</div>
}

const categories = [
  { label: "Bulan Ini", value: "this_month" },
  { label: "Bulan Lalu", value: "last_month" },
  { label: "3 Bulan Lalu", value: "last_3_month" },
]

function SuspendedMGMHistoryEXP({ items }) {
  const isMobile = useIsMobile()
  const searchParams = useSearchParams()
  const datalayer = useDatalayer()
  const swiperRef = useRef(null)
  const [selectedCategory, setSelectedCategory] = useState(
    searchParams.get("history-exp") || categories[0].label
  )

  const [dataHistory, setDataHistory] = useState()

  const handleGetHistory = async () => {
    try {
      const { data } = await ClientApiList.getMGMHistory()
      setDataHistory({ ...data.data.data })
    } catch (err) {
      console.log("[Error]", err)
    }
  }

  useEffect(() => {
    handleGetHistory()
  }, [])

  useEffect(() => {
    if (searchParams.get("history-exp"))
      setSelectedCategory(searchParams.get("history-exp"))
  }, [searchParams])

  const handleRenderList = () => {
    if (dataHistory) {
      if (selectedCategory === "Bulan Ini")
        return dataHistory.history_this_month
      else if (selectedCategory === "Bulan Lalu")
        return dataHistory.history_last_month
      else return dataHistory.histroy_last_3_month
    } else return []
  }

  const list = handleRenderList()

  return (
    <div className="lg:border-[1px] border-bg-6 bg-bg-3 p-[15px] lg:p-[19px] lg:col-start-3 lg:row-start-2 lg:row-span-2 rounded-[10px] max-w-[609px] w-[calc(100%-20px)] lg:w-full mx-auto lg:max-w-none lg:max-h-[485px]">
      <div className="text-[16px] lg:text-[24px] leading-[22px] lg:leading-[32px] font-bold font-font-family-7 mb-[15px] text-text-2">
        Riwayat Poin
      </div>
      {/* <div className='whitespace-nowrap flex items-center gap-[5px] mb-[10px] lg:mb-[20px] overflow-auto'> */}
      <SwiperComp
        slidesPerView={"auto"}
        spaceBetween={5}
        className="!mb-[10px] lg:!mb-[20px]"
        ref={swiperRef}
      >
        {categories?.map((category, index) => (
          <SwiperSlide key={category.id} className="!w-fit">
            <div role="button" tabIndex={0}
              onClick={() =>
                datalayer.push({
                  event: "general_event",
                  event_name: "click_content",
                  feature: "special landing page",
                  content_section: "mgm",
                  content_brand: "branded", // e.g branded or unbranded
                  content_type: "not available", // e.g article, carousel, video, etc
                  content_category: "not available", // e.g music, travel, automotive, etc
                  content_position: index + 1, // e.g 1, 2, 3, etc (integer)
                  content_name: category.label, // e.g the_ultimate_xperience, etc
                  publish_date: "not available", // e.g 23-10-2023 (date of content publish)
                  campaign_name: "all", // e.g ride your flavour 2023
                  engagement_type: "interaction",
                })
              }
            >
              <CategoryCard
                className={`!px-[10px] ${selectedCategory === category.label ? "!border-cta-1" : "!border-cta-4"} inline-block !break-keep !lg:px-[10px] !lg:py-[10px] lg:leading-[20px]`}
                size="small"
                intent={
                  selectedCategory === category.label ? "active" : "default"
                }
                categoryName="history-exp"
                value={category.label}
              >
                {category.label}
              </CategoryCard>
            </div>
          </SwiperSlide>
        ))}
      </SwiperComp>
      {/* </div> */}
      {list.length > 0 ? (
        <ul className="md:rounded-[10px] grid bg-bg-1 gap-[10px] md:p-[15px] w-full overflow-auto md:max-h-[339px]">
          {list.map((item) => (
            <li key={item?.uuid}>
              <HistoryEXPCard
                title={`Berhasil Undang ${item?.fullname}`}
                date={item?.create_date}
                status={item?.status}
                exp={item?.point}
              />
            </li>
          ))}
        </ul>
      ) : (
        <div className="w-[100%] h-[100%] min-h-[390px] relative">
          <Image
            className="absolute inset-0 max-h-[390px] lg:max-h-[339px]"
            objectFit="contain"
            alt="bg"
            src={`${urlAsset()}/mgm/empty-list-${isMobile ? "mobile" : "desktop"}.png`}
          />
        </div>
      )}
    </div>
  )
}

SuspendedMGMHistoryEXP.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.object),
}

export function MGMHistoryEXP(props) {
  return (
    <Suspense fallback={<MGMHistoryEXPFallback />}>
      <SuspendedMGMHistoryEXP {...props} />
    </Suspense>
  )
}
