"use client"
import ButtonAction from "@/components/shared/button-action/button-action"
import PopupAction from "@/components/shared/popup-action/popup-action"
import React from "react"
import PropTypes from "prop-types"
import { Image } from "@/components/shared/image/image"
import { usePathname } from "next/navigation"
import { urlAsset } from "@/helper/utils";

export default function PopupOTP({
  isOpen,
  setStep,
  setIsOpen,
  isSms,
  handleRequestOTP,
}) {
  const pathname = usePathname()
  const handleReqOTP = async () => {
    await handleRequestOTP()
    setIsOpen(false)
    setStep(2)
  }

  const user = process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"
  return (
    <PopupAction isShowing={isOpen} href={pathname} zIndex="z-[20]">
      <div className="max-w-[304px]">
        <div className="rounded-[10px] w-[304px] h-[177px] bg-bg-1 mb-[20px] relative">
          <Image
            className="absolute inset-0"
            alt="delete"
            src={`${urlAsset()}/profile-edit/otp${!isSms ? "-call" : ""}.png`}
          />
        </div>
        <div
          data-testid="popup-otp-title"
          className="text-[20px] font-black font-font-family-8 mb-[5px] leading-[27px] text-text-2"
          // style={{ background: 'var(--text_2)', WebkitTextFillColor: 'transparent' }}
        >
          {isSms ? (
            <>
              Kode OTP akan <br /> dikirimkan ke nomor {user}
            </>
          ) : (
            <span className="normal-case">
              {user} bakal menerima missed call
            </span>
          )}
        </div>
        <div
          data-testid="popup-otp-description"
          className="text-[14px] leading-[20px] mb-[20px] text-center text-text-2"
          // style={{ background: 'var(--text_2)', WebkitTextFillColor: 'transparent' }}
        >
          {isSms ? (
            <>
              Kami akan mengirimkan kode OTP lewat SMS untuk verifikasi akun{" "}
              {user}.
            </>
          ) : (
            <>
              Kami akan memverifikasikan akun {user} dengan 4 digit terakhir
              dari nomor telepon <i>missed call</i> yang akan {user} terima
            </>
          )}
        </div>
        <div className="flex items-center justify-between gap-[10px]">
          <ButtonAction
            className="py-[15px] px-[20px] leading-[20px]"
            intent="primary"
            onClick={() => handleReqOTP()}
          >
            Mengerti
          </ButtonAction>
        </div>
      </div>
    </PopupAction>
  )
}

PopupOTP.propTypes = {
  isOpen: PropTypes.bool,
  setStep: PropTypes.func,
  setIsOpen: PropTypes.func,
  isSms: PropTypes.bool,
  handleRequestOTP: PropTypes.func,
}
