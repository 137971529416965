"use client";
import React from "react";
import LeaderboardTopUserCard from "../leaderboard-top/card";
import LeaderboardTopUserName from "../leaderboard-top/name";
import LeaderboardTopUserExp from "../leaderboard-top/exp";
import LeaderboardTopUserPos from "../leaderboard-top/position";
import LeaderboardTopUserAvatar from "../leaderboard-top/avatar";
import { Image } from "@/components/shared/image/image";
import { urlAsset } from "@/helper/utils";

export default function TopUserList({ topUsers, isLoading }) {
  // console.log(isLoading, "is")
  const renderItems = () => {
    if (isLoading)
      return [1, 2, 3].map((item, index, arr) => {
        return (
          <li
            data-testid={`top-user-skeleton-${index}`}
            style={{ order: index === 0 ? 2 : index === 2 ? 3 : 1 }}
            key={item}
          >
            <LeaderboardTopUserCard
              className={
                index === 0
                  ? `min-w-[122.7px] md:max-w-[172.78px] md:px-[0] md:min-w-[167px] pb-[25.8px] md:pb-[54.15px]`
                  : "mt-[7.8px] md:mt-[11px]"
              }
            >
               {index === 0 ? (
                <Image className='absolute inset-0 z-[0]' src={`${urlAsset()}/user-profile/rectangle-lg.svg`} alt='avatar' />
              ) : (
                <Image className='absolute inset-0 z-[0]' src={`${urlAsset()}/user-profile/rectangle-sm.svg`} alt='avatar' />
              )}
              <LeaderboardTopUserName>
                <div className="max-w-[70%] max-h-[16px] md:max-h-[22px] my-auto mx-auto bg-[#D9D9D9] animate-pulse absolute inset-0"></div>
              </LeaderboardTopUserName>
              <LeaderboardTopUserExp
                className={
                  index === 0
                    ? "text-[14px] md:text-[20px] leading-[19px] md:leading-[27px]"
                    : ""
                }
              >
                0
              </LeaderboardTopUserExp>
              <LeaderboardTopUserPos
                className={
                  index === 0
                    ? `bottom-[-42.7px] md:bottom-[-54.6px] md:text-[27.6px] md:leading-[37px] w-[56px] md:w-[72px] leading-[27px] h-[62.7px] md:h-[80.6px]`
                    : ""
                }
              >
                {index === 0 ? (
                <Image className='absolute inset-0 z-[-1]' src={`${urlAsset()}/user-profile/hexagon.svg`} alt='avatar' />
              ) : (
                <Image className='absolute inset-0 z-[-1]' src={`${urlAsset()}/user-profile/hexagon-sm.svg`} alt='avatar' />
              )}
                {index + 1}
              </LeaderboardTopUserPos>
              <LeaderboardTopUserAvatar
                className={
                  index === 0
                    ? "w-[70.6px] md:w-[96.8px] min-h-[70.6px] md:h-[96.8px] md:mb-[13.69px]"
                    : ""
                }
              >
                {index === 0 ? (
                  <Image src={`${urlAsset()}/user-profile/profile-lg.svg`} alt='avatar' />
                ) : (
                  <Image src={`${urlAsset()}/user-profile/profile-sm.svg`} alt='avatar' />
                )}
              </LeaderboardTopUserAvatar>
            </LeaderboardTopUserCard>
          </li>
        );
      });
    else {
      return topUsers.map((user, index) => (
        <li
          data-testid={`top-user-${index}`}
          key={index}
          style={{ order: index === 0 ? 2 : index === 2 ? 3 : 1 }}
        >
          <LeaderboardTopUserCard
            className={
              index === 0
                ? `min-w-[122.7px] md:max-w-[172.78px] md:px-[0] md:min-w-[167px] pb-[25.8px] md:pb-[54.15px]`
                : "mt-[7.8px] md:mt-[11px]"
            }
          >
            {index === 0 ? (
                <Image className='absolute inset-0 z-[0]' src={`${urlAsset()}/user-profile/rectangle-lg.svg`} alt='avatar' />
              ) : (
                <Image className='absolute inset-0 z-[0]' src={`${urlAsset()}/user-profile/rectangle-sm.svg`} alt='avatar' />
              )}
            <LeaderboardTopUserName>{user[1]}</LeaderboardTopUserName>
            <LeaderboardTopUserExp
              className={
                index === 0
                  ? "text-[14px] md:text-[20px] leading-[19px] md:leading-[27px]"
                  : ""
              }
            >
              {user[2]}
            </LeaderboardTopUserExp>
            <LeaderboardTopUserPos
              className={
                index === 0
                  ? `bottom-[-42.7px] md:bottom-[-54.6px] md:text-[27.6px] md:leading-[37px] w-[56px] md:w-[72px] leading-[27px] h-[62.7px] md:h-[80.6px]`
                  : ""
              }
            >
              {index === 0 ? (
                <Image className='absolute inset-0 z-[-1]' src={`${urlAsset()}/user-profile/hexagon.svg`} alt='avatar' />
              ) : (
                <Image className='absolute inset-0 z-[-1]' src={`${urlAsset()}/user-profile/hexagon-sm.svg`} alt='avatar' />
              )}
              {index + 1}
            </LeaderboardTopUserPos>
            <LeaderboardTopUserAvatar
              className={
                index === 0
                  ? "w-[70.6px] md:w-[96.8px] min-h-[70.6px] md:h-[96.8px] md:mb-[13.69px]"
                  : ""
              }
            >
              {index === 0 ? (
                <Image src={`${urlAsset()}/user-profile/profile-lg.svg`} alt='avatar' />
              ) : (
                <Image src={`${urlAsset()}/user-profile/profile-sm.svg`} alt='avatar' />
              )}
            </LeaderboardTopUserAvatar>
          </LeaderboardTopUserCard>
        </li>
      ));
    }
  };

  return (
    <ol className="flex justify-center gap-[5px] md:gap-[10px] pb-[43.5px] md:pb-[58px] mb-[20px] md:mb-[30px] md:max-w-[452px] md:mx-auto">
      {renderItems()}
    </ol>
  );
}

{
  /* <li className="grow-[1]">
    <LeaderboardTopUserCard className="bg-[url('/assets/${process.env.NEXT_PUBLIC_NAME}/user-profile/rectangle.png')] min-w-[122.7px] pt-[7.8px] pb-[30px]">
        <LeaderboardTopUserName>R****a</LeaderboardTopUserName>
        <LeaderboardTopUserExp className='text-[16px] leading-[22px]'>50.000 EXP</LeaderboardTopUserExp>
        <LeaderboardTopUserAvatar className='w-[70px] min-h-[70px] mb-[9.45px]'>
            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                <path d="M22.9914 19.4383C27.1489 19.4383 30.5191 16.068 30.5191 11.9106C30.5191 7.75313 27.1489 4.38287 22.9914 4.38287C18.834 4.38287 15.4637 7.75313 15.4637 11.9106C15.4637 16.068 18.834 19.4383 22.9914 19.4383Z" fill="white" />
                <path d="M38.0468 33.5527C38.0468 38.2293 38.0468 42.0213 22.9914 42.0213C7.93604 42.0213 7.93604 38.2293 7.93604 33.5527C7.93604 28.8761 14.6771 25.084 22.9914 25.084C31.3058 25.084 38.0468 28.8761 38.0468 33.5527Z" fill="white" />
            </svg>
        </LeaderboardTopUserAvatar>
        <LeaderboardTopUserPos className="bottom-[-27.49px] w-[49px] leading-[27px] h-[55px] bg-[url('/assets/${process.env.NEXT_PUBLIC_NAME}/user-profile/hexagon.png')]">1</LeaderboardTopUserPos>
    </LeaderboardTopUserCard>
</li> */
}
