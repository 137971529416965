"use client"
import React from "react"
import { Image } from "@/components/shared/image/image"
import { useRouter } from "next/navigation"
import PropTypes from "prop-types"
import { SuccessIcon } from "@/components/sections/article-details"
import { cn } from "@/lib/utils"

const ExitIcon = () => {
  if (process.env.NEXT_PUBLIC_NAME === "marlboro")
    return (
      <svg
        className="absolute inset-0 max-w-[40px] max-h-[40px]"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
          fill="#D1091E"
        />
        <path
          d="M18.6673 18.6654L13.334 13.332M18.6673 13.332L13.334 18.6654"
          stroke="white"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    )
  if (process.env.NEXT_PUBLIC_NAME !== "amild")
    return (
      <svg
        className="absolute inset-0 max-w-[40px] max-h-[40px]"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
      >
        <circle
          cx="24"
          cy="24"
          r="19.75"
          fill="url(#paint0_linear_4840_16586)"
          stroke="url(#paint1_linear_4840_16586)"
          strokeWidth="0.5"
        />
        <path
          d="M16.3891 16.4237C16.6386 16.1751 16.9768 16.0354 17.3294 16.0354C17.682 16.0354 18.0202 16.1751 18.2697 16.4237L23.9823 22.1243L29.6948 16.4237C29.8575 16.2489 30.0643 16.1212 30.2936 16.0539C30.5229 15.9866 30.7662 15.9822 30.9977 16.0413C31.2293 16.1004 31.4406 16.2207 31.6094 16.3896C31.7782 16.5585 31.8982 16.7696 31.9568 17.0009C32.0161 17.2317 32.012 17.4742 31.9448 17.7028C31.8777 17.9315 31.7501 18.1379 31.5754 18.3003L25.8628 24.0009L31.5754 29.7014C31.7505 29.8637 31.8786 30.0701 31.946 30.2989C32.0134 30.5278 32.0178 30.7705 31.9586 31.0016C31.8994 31.2326 31.7788 31.4435 31.6096 31.6119C31.4404 31.7804 31.2287 31.9001 30.997 31.9586C30.7657 32.0178 30.5227 32.0136 30.2936 31.9467C30.0644 31.8797 29.8576 31.7523 29.6948 31.578L23.9823 25.8774L18.2697 31.578C18.0173 31.8124 17.6837 31.94 17.3389 31.9341C16.9942 31.9282 16.6652 31.7891 16.4211 31.5461C16.1776 31.3025 16.0382 30.9742 16.0323 30.6302C16.0263 30.2862 16.1542 29.9533 16.3891 29.7014L22.1017 24.0009L16.3891 18.3003C16.14 18.0513 16 17.7139 16 17.362C16 17.0102 16.14 16.6727 16.3891 16.4237Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_4840_16586"
            x1="50.5"
            y1="-1.5"
            x2="13.5833"
            y2="41.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="0.302701" stopColor="white" stopOpacity="0.471684" />
            <stop offset="1" stopColor="white" stopOpacity="0.1" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_4840_16586"
            x1="6.5"
            y1="41"
            x2="41"
            y2="6.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0" />
            <stop offset="0.735223" stopColor="white" stopOpacity="0.2" />
            <stop offset="1" stopColor="white" stopOpacity="0.55" />
          </linearGradient>
        </defs>
      </svg>
    )

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M4 20C4 11.1634 11.1634 4 20 4C28.8366 4 36 11.1634 36 20C36 28.8366 28.8366 36 20 36C11.1634 36 4 28.8366 4 20Z"
        fill="#ED1C24"
      />
      <path
        d="M13.6563 13.6866C13.8642 13.4794 14.146 13.363 14.4399 13.363C14.7337 13.363 15.0155 13.4794 15.2234 13.6866L19.9839 18.4371L24.7444 13.6866C24.8799 13.541 25.0523 13.4345 25.2434 13.3784C25.4345 13.3223 25.6372 13.3187 25.8301 13.3679C26.0231 13.4172 26.1992 13.5174 26.3399 13.6582C26.4805 13.7989 26.5805 13.9749 26.6294 14.1675C26.6788 14.3599 26.6753 14.562 26.6194 14.7525C26.5635 14.9431 26.4571 15.1151 26.3115 15.2504L21.551 20.0009L26.3115 24.7513C26.4575 24.8866 26.5642 25.0586 26.6204 25.2493C26.6766 25.44 26.6802 25.6422 26.6309 25.8348C26.5815 26.0274 26.481 26.2031 26.34 26.3434C26.199 26.4838 26.0226 26.5836 25.8296 26.6323C25.6368 26.6816 25.4343 26.6782 25.2433 26.6224C25.0524 26.5666 24.88 26.4604 24.7444 26.3151L19.9839 21.5647L15.2234 26.3151C15.0131 26.5105 14.7351 26.6169 14.4478 26.6119C14.1605 26.607 13.8864 26.4911 13.6829 26.2886C13.48 26.0856 13.3639 25.812 13.3589 25.5253C13.354 25.2386 13.4606 24.9612 13.6563 24.7513L18.4168 20.0009L13.6563 15.2504C13.4487 15.043 13.332 14.7617 13.332 14.4685C13.332 14.1753 13.4487 13.8941 13.6563 13.6866Z"
        fill="white"
      />
    </svg>
  )
}

export default function CarouselHeader({ exp, finished, imgUrl, paramPoint }) {
  const { back } = useRouter()
  return (
    <div className="absolute top-[12px] lg:top-[15px] w-full min-h-[20px] z-[5] flex items-center justify-between px-[15px] h-fit">
      <div className="flex items-center gap-[5px] lg:py-[8px]">
        {finished === 1 || paramPoint ? (
          <SuccessIcon />
        ) : (
          <div className="w-[24px] h-[24px] relative overflow-hidden">
            <Image
              alt="cover"
              objectFit="cover"
              src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/reward-profile.svg`}
            />
          </div>
        )}
        <div
          data-testid="exp"
          className={cn(
            "text-[14px] leading-[20px] font-bold font-font-family-7",
            process.env.NEXT_PUBLIC_NAME === "djisamsoe"
              ? "text-[white]"
              : process.env.NEXT_PUBLIC_NAME === "marlboro"
                ? "text-text-1"
                : ""
          )}
        >
          {exp} Poin
        </div>
      </div>
      {/* <Link className="max-h-[40px] h-fit w-fit" href="#"> */}
      <button className="w-[40px] h-[40px] relative" onClick={back}>
        <ExitIcon />
      </button>
      {/* </Link> */}
    </div>
  )
}

CarouselHeader.propTypes = {
  exp: PropTypes.string,
  imgUrl: PropTypes.string,
  paramPoint: PropTypes.bool,
  finished: PropTypes.bool,
}
