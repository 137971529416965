/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/src/styles.scss";
import "./audio-player.scss";
import clsx from "clsx";
import {
  IconJumpStepBackward,
  IconJumpStepForward,
  IconLoop,
  IconLoopOff,
  IconMessageText,
  IconMessageTextFilled,
  IconPause,
  IconPlay,
  IconVolumeHigh,
  IconVolumeHighFill,
  IconVolumeLow,
  IconVolumeLowFill,
  IconVolumeMute,
  IconVolumeMuteFill,
} from "../components/submission-icons";
import { useIsMobile, useIsTablet } from "@/hooks/use-is-mobile";
import { useEffect, useRef, useState } from "react";
import AudioPlayerVolume from "./components/audio-player-volume";
import { isIOS } from "@/lib/utils";
import { Spinner } from "@/components/shared/spinner";
import PropTypes from "prop-types";

const ModalAudioPlayer = ({
  isOpen,
  file,
  openDetail,
  setOpenDetail,
  isAutoPlay,
}) => {
  const isMobile = useIsMobile();
  const audioRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [audioFile, setAudioFile] = useState({ preview: "" });
  const [currentVolume, setCurrentVolume] = useState({
    value: "0.5",
    position: "50",
  });

  const onVolumeChange = (event) => {
    let newValue = event?.target?.volume?.toFixed(1);
    setCurrentVolume({ value: newValue, position: newValue * 100 });
  };

  const getIconVolume = (isFill = false) => {
    const volume = currentVolume?.value;
    switch (true) {
      case Number(volume) > 0 && Number(volume) < 0.5:
        return isFill ? <IconVolumeLowFill /> : <IconVolumeLow />;
      case Number(volume) < 0.1:
        return isFill ? <IconVolumeMuteFill /> : <IconVolumeMute />;
      default:
        return isFill ? <IconVolumeHighFill /> : <IconVolumeHigh />;
    }
  };

  const customVolumeControls = () => {
    if (isIOS()) return [];
    return [
      isMobile ? (
        <AudioPlayerVolume
          key="volume"
          audioRef={audioRef}
          currentVolume={currentVolume}
          setCurrentVolume={setCurrentVolume}
          getIconVolume={getIconVolume}
        />
      ) : (
        RHAP_UI.VOLUME
      ),
    ];
  };

  useEffect(() => {
    if (!isOpen) return;

    getAudio();

    return () => {
      if (audioFile?.preview?.startsWith("blob:"))
        URL.revokeObjectURL(audioFile?.preview);
    };
  }, [isOpen]);

  const getAudio = () => {
    setLoading(true);
    const audio = file;
    const audioType = getAudioType(
      file?.preview.slice(file?.preview.length - 3, file?.preview.length)
    );
    console.log("raw audio =>", typeof file, file);

    if (audio instanceof File) {
      setAudioFile(audio);

      setTimeout(() => {
        setLoading(false);
      }, 1000);

      return;
    }

    urlToBlob(audio?.preview, audioType?.type).then((blobUrl) => {
      if (blobUrl) {
        const previewObj = { preview: blobUrl };

        if (isIOS()) {
          const jsonString = JSON.stringify(previewObj);
          const newFile = new File(
            [jsonString],
            `sample.${audioType?.extension}`,
            {
              type: `audio/${audioType?.type}`,
            }
          );
          const result = Object.assign(newFile, previewObj);
          console.log("new file audio =>", result);
          setAudioFile(result);
        } else {
          setAudioFile(previewObj);
        }

        setLoading(false);
      }
    });
  };

  async function urlToBlob(url, type) {
    try {
      const response = await fetch(url, { mode: "cors" });
      if (!response.ok) throw new Error("Failed to fetch the audio file.");

      const blob = await response.blob();
      const validBlob = new Blob([blob], { type: `audio/${type}` });
      console.log("validBlob =>", validBlob);

      if (validBlob.type.startsWith("audio/")) {
        const blobUrl = URL.createObjectURL(validBlob);
        return blobUrl;
      } else {
        throw new Error("Invalid audio file type:", validBlob.type);
      }
    } catch (error) {
      console.error("Error converting URL to blob:", error);
    }
  }

  const getAudioType = (extension) => {
    switch (extension) {
      case "wav":
        return { type: extension, extension: extension };
      default:
        return { type: "mpeg", extension: "mp3" };
    }
  };

  const handlePlayWithDelay = (type) => {
    setTimeout(() => {
      if (audioRef?.current) {
        console.log(type);
        audioRef?.current.audio.current.play();
      }
    }, 1000);
  };

  return (
    <div className="relative">
      {!loading ? (
        <AudioPlayer
          key={audioFile?.preview}
          ref={audioRef}
          autoPlay={isAutoPlay && !isIOS()}
          autoPlayAfterSrcChange={false}
          crossOrigin="anonymous"
          className={clsx("!bg-transparent", "audio_player")}
          volume={currentVolume?.value}
          onVolumeChange={onVolumeChange}
          src={audioFile?.preview}
          showDownloadProgress={false}
          showFilledVolume
          onPlay={() => handlePlayWithDelay("onPlay")}
          onPlayError={() => handlePlayWithDelay("onPlayError")}
          onError={() => handlePlayWithDelay("onError")}
          progressJumpSteps={{
            backward: 15000,
            forward: 15000,
          }}
          customIcons={{
            play: <IconPlay />,
            pause: <IconPause />,
            rewind: <IconJumpStepBackward />,
            forward: <IconJumpStepForward />,
            volume: getIconVolume(),
            volumeMute: <IconVolumeMute />,
            loop: <IconLoop />,
            loopOff: <IconLoopOff />,
          }}
          customControlsSection={[
            RHAP_UI.VOLUME_CONTROLS,
            RHAP_UI.MAIN_CONTROLS,
            RHAP_UI.ADDITIONAL_CONTROLS,
          ]}
          customAdditionalControls={[
            <ButtonBandDetail
              key="detail"
              open={openDetail}
              close={setOpenDetail}
            />,
            RHAP_UI.LOOP,
          ]}
          customVolumeControls={customVolumeControls()}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};
ModalAudioPlayer.propTypes = {
  isOpen: PropTypes.bool,
  file: PropTypes.object,
  openDetail: PropTypes.bool,
  setOpenDetail: PropTypes.func,
  isAutoPlay: PropTypes.bool,
};

const ButtonBandDetail = ({ open, close }) => {
  const isTablet = useIsTablet();
  return (
    !isTablet && (
      <button onClick={close}>
        {open ? <IconMessageTextFilled /> : <IconMessageText />}
      </button>
    )
  );
};
ButtonBandDetail.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default ModalAudioPlayer;
